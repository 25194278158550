// *** Version: 6 ***

/* eslint max-len: 0, object-curly-newline: 0 */
const rawPricing = [
  {
    name: 'Manteau & veste',
    slug: 'mant',
    types: [],
    hasMostCommonCategory: true,
    showInPlugin: true,
    categories: [
      {
        name: 'Cintrer',
        showInPlugin: true,
        items: [
          { name: 'Épaules (x2)', price: 47, liningUnitPrice: 15, showInPlugin: true, id: '6.mant-cin-1' },
          { name: 'Manches (x2)', price: 59, liningUnitPrice: 0, showInPlugin: true, id: '6.mant-cin-2' },
          { name: 'Taille', price: 37, liningUnitPrice: 13, showInPlugin: true, id: '6.mant-cin-3' },
          { name: 'Dos', price: 20, liningUnitPrice: 7, showInPlugin: true, id: '6.mant-cin-4', mostCommonCategoryPosition: 2, mostCommonCategoryLabel: 'Cintrer dos' },
        ],
      },
      {
        name: 'Élargir',
        showInPlugin: true,
        items: [
          { name: 'Épaules (x2)', price: 52, liningUnitPrice: 18, showInPlugin: true, id: '6.mant-ela-1' },
          { name: 'Manches (x2)', price: 64, liningUnitPrice: 0, showInPlugin: true, id: '6.mant-ela-2' },
          { name: 'Taille', price: 46, liningUnitPrice: 15, showInPlugin: true, id: '6.mant-ela-3' },
          { name: 'Dos', price: 25, liningUnitPrice: 8, showInPlugin: true, id: '6.mant-ela-4' },
        ],
      },
      {
        name: 'Raccourcir',
        showInPlugin: true,
        items: [
          { name: 'Longueur', price: 29, liningUnitPrice: 12, showInPlugin: true, id: '6.mant-rac-1', mostCommonCategoryPosition: 1, mostCommonCategoryLabel: 'Raccourcir longueur' },
          { name: 'Manche (x2 - par le bas)', price: 25, liningUnitPrice: 5, id: '6.mant-rac-2' },
          { name: 'Manche (x2 - par l’épaule)', price: 65, liningUnitPrice: 15, showInPlugin: true, id: '6.mant-rac-3' },
        ],
      },
      {
        name: 'Rallonger',
        showInPlugin: true,
        items: [
          { name: 'Longueur', price: 35, liningUnitPrice: 12, showInPlugin: true, id: '6.mant-ral-1' },
          { name: 'Manche (x2 - par le bas)', price: 27, liningUnitPrice: 25, id: '6.mant-ral-2' },
          { name: 'Manche (x2 - par l’épaule)', price: 85, liningUnitPrice: 25, showInPlugin: true, id: '6.mant-ral-3' },
        ],
      },
      {
        name: 'Broderie main',
        items: [
          { name: 'Initiales', price: 20, id: '6.mant-bro-1', multiple: true },
          { name: 'Un mot (10 lettres - petite police)', price: 30, id: '6.mant-bro-2', multiple: true },
          { name: 'Un mot (10 lettres - grosse police)', price: 70, id: '6.mant-bro-3', multiple: true },
          { name: 'Un mot (10 lettres - grosse police pleine)', price: 110, id: '6.mant-bro-11', multiple: true },
          { name: 'Dessin creux < 5cm', price: 20, id: '6.mant-bro-4', multiple: true },
          { name: 'Dessin creux < 12cm', price: 30, id: '6.mant-bro-5', multiple: true },
          { name: 'Dessin creux < 25cm', price: 35, id: '6.mant-bro-6', multiple: true },
          { name: 'Dessin plein forme simple < 5cm', price: 45, id: '6.mant-bro-7', multiple: true },
          { name: 'Dessin plein complexe < 5cm', price: 75, id: '6.mant-bro-8', multiple: true },
          { name: 'Dessin plein forme simple < 12cm', price: 65, id: '6.mant-bro-9', multiple: true },
          { name: 'Dessin plein complexe < 12cm', price: 140, id: '6.mant-bro-10', multiple: true },
        ],
      },
      {
        name: 'Divers',
        items: [
          { name: 'Doublure : changement long', price: 130, id: '6.mant-div-1' },
          { name: 'Doublure : changement mi-long', price: 96, id: '6.mant-div-2' },
          { name: 'Coudières (x2) : ajout / remplacement', price: 24, id: '6.mant-div-3' },
          { name: 'Épaulettes (x2) : ajout / remplacement / retrait', price: 30, liningUnitPrice: 17, id: '6.mant-div-4' },
          { name: 'Zip : ajout/remplacement', price: 45, id: '6.mant-div-5', multiple: true },
          { name: 'Zip : retrait', price: 35, id: '6.mant-div-6', multiple: true },
          { name: 'Poche (x1) : changement de doublure', price: 20, id: '6.mant-div-7', multiple: true },
          { name: 'Poche (x1) : ajout / remplacement', price: 30, id: '6.mant-div-8', multiple: true },
          { name: 'Col : ajout / remplacement', price: 45, id: '6.mant-div-9' },
          { name: 'Boutonnière (petite) : création', price: 68, liningUnitPrice: 20, id: '6.mant-div-10', hidden: true },
          { name: 'Boutonnière (grande) : création', price: 136, liningUnitPrice: 40, id: '6.mant-div-11', hidden: true },
          { name: 'Boutonnière (x1) : création', price: 10, liningUnitPrice: 3, id: '6.mant-div-18' },
          { name: 'Bouton (x1) : ajout / remplacement', price: 3, id: '6.mant-div-12', multiple: true, mostCommonCategoryPosition: 3, mostCommonCategoryLabel: 'Bouton à l‘unité' },
          { name: 'Trou (x1) : remaillage', price: 10, id: '6.mant-div-19', multiple: true },
          { name: 'Déchirure : réparation', price: 8, id: '6.mant-div-13', multiple: true, mostCommonCategoryPosition: 5 },
          { name: 'Passant : ajout / remplacement', price: 10, id: '6.mant-div-14', multiple: true },
          { name: 'Couture d’appoint / décousu - simple', price: 7, id: '6.mant-div-15', multiple: true, mostCommonCategoryPosition: 4 },
          { name: 'Couture d’appoint / décousu - complexe', price: 15, id: '6.mant-div-16', multiple: true },
          { name: 'Couture complexe avec empiècement', price: 28, id: '6.mant-div-17', multiple: true },
        ],
      },
    ],
  },


  {
    name: 'Haut',
    slug: 'haut',
    hasMostCommonCategory: true,
    showInPlugin: true,
    types: [
      { slug: 'SIMPLE', name: 'basique' },
      { slug: 'COMPLEX', name: 'habillé' },
    ],
    categories: [
      {
        name: 'Cintrer',
        showInPlugin: true,
        items: [
          { name: 'Épaules (x2)', type: 'SIMPLE', price: 14, showInPlugin: true, id: '6.haut-cin-1' },
          { name: 'Manches (x2)', type: 'SIMPLE', price: 14, showInPlugin: true, id: '6.haut-cin-2' },
          { name: 'Taille', type: 'SIMPLE', price: 14, showInPlugin: true, id: '6.haut-cin-3' },
          { name: 'Dos', type: 'SIMPLE', price: 9, showInPlugin: true, id: '6.haut-cin-4', mostCommonCategoryPosition: 5, mostCommonCategoryLabel: 'Cintrer dos' },
          { name: 'Épaules (x2)', type: 'COMPLEX', price: 29, showInPlugin: true, id: '6.haut-cin-5' },
          { name: 'Manches (x2)', type: 'COMPLEX', price: 29, showInPlugin: true, id: '6.haut-cin-6' },
          { name: 'Taille', type: 'COMPLEX', price: 25, showInPlugin: true, id: '6.haut-cin-7' },
          { name: 'Dos', type: 'COMPLEX', price: 18, showInPlugin: true, id: '6.haut-cin-8', mostCommonCategoryPosition: 5, mostCommonCategoryLabel: 'Cintrer dos' },
        ],
      },
      {
        name: 'Élargir',
        showInPlugin: true,
        items: [
          { name: 'Épaules (x2)', type: 'SIMPLE', price: 15, showInPlugin: true, id: '6.haut-ela-1' },
          { name: 'Manches (x2)', type: 'SIMPLE', price: 15, showInPlugin: true, id: '6.haut-ela-2' },
          { name: 'Taille', type: 'SIMPLE', price: 15, showInPlugin: true, id: '6.haut-ela-3' },
          { name: 'Dos', type: 'SIMPLE', price: 10, showInPlugin: true, id: '6.haut-ela-4' },
          { name: 'Épaules (x2)', type: 'COMPLEX', price: 32, showInPlugin: true, id: '6.haut-ela-5' },
          { name: 'Manches (x2)', type: 'COMPLEX', price: 28, showInPlugin: true, id: '6.haut-ela-6' },
          { name: 'Taille', type: 'COMPLEX', price: 32, showInPlugin: true, id: '6.haut-ela-7' },
          { name: 'Dos', type: 'COMPLEX', price: 20, showInPlugin: true, id: '6.haut-ela-8' },
        ],
      },
      {
        name: 'Raccourcir',
        showInPlugin: true,
        items: [
          { name: 'Longueur', type: 'SIMPLE', price: 8, showInPlugin: true, id: '6.haut-rac-1', mostCommonCategoryPosition: 4, mostCommonCategoryLabel: 'Raccourcir longueur' },
          { name: 'Manches (x2 - par le bas)', type: 'SIMPLE', price: 10, showInPlugin: true, id: '6.haut-rac-2' },
          { name: 'Manches (x2 - par l’épaule)', type: 'SIMPLE', price: 28, id: '6.haut-rac-3' },
          { name: 'Longueur', type: 'COMPLEX', price: 21, showInPlugin: true, id: '6.haut-rac-4', mostCommonCategoryPosition: 4, mostCommonCategoryLabel: 'Raccourcir longueur' },
          { name: 'Manches (x2 - par le bas)', type: 'COMPLEX', price: 23, showInPlugin: true, id: '6.haut-rac-5' },
          { name: 'Manches (x2 - par l’épaule)', type: 'COMPLEX', price: 65, id: '6.haut-rac-6' },
        ],
      },
      {
        name: 'Rallonger',
        showInPlugin: true,
        items: [
          { name: 'Longueur', type: 'SIMPLE', price: 10, showInPlugin: true, id: '6.haut-ral-1' },
          { name: 'Manches (x2 - par le bas)', type: 'SIMPLE', price: 12, showInPlugin: true, id: '6.haut-ral-2' },
          { name: 'Manches (x2 - par l’épaule)', type: 'SIMPLE', price: 30, id: '6.haut-ral-3' },
          { name: 'Longueur', type: 'COMPLEX', price: 24, showInPlugin: true, id: '6.haut-ral-4' },
          { name: 'Manches (x2 - par le bas)', type: 'COMPLEX', price: 27, showInPlugin: true, id: '6.haut-ral-5' },
          { name: 'Manches (x2 - par l’épaule)', type: 'COMPLEX', price: 75, id: '6.haut-ral-6' },
        ],
      },
      {
        name: 'Broderie main',
        items: [
          { name: 'Initiales', type: 'SIMPLE', price: 10, id: '6.haut-bro-1', multiple: true },
          { name: 'Un mot (10 lettres - petite police)', type: 'SIMPLE', price: 15, id: '6.haut-bro-2', multiple: true },
          { name: 'Un mot (10 lettres - grosse police)', type: 'SIMPLE', price: 45, id: '6.haut-bro-3', multiple: true },
          { name: 'Un mot (10 lettres - grosse police pleine)', type: 'SIMPLE', price: 80, id: '6.haut-bro-21', multiple: true },

          { name: 'Initiales', type: 'COMPLEX', price: 17, id: '6.haut-bro-4', multiple: true },
          { name: 'Un mot (10 lettres - petite police)', type: 'COMPLEX', price: 25, id: '6.haut-bro-5', multiple: true },
          { name: 'Un mot (10 lettres - grosse police)', type: 'COMPLEX', price: 49, id: '6.haut-bro-6', multiple: true },
          { name: 'Un mot (10 lettres - grosse police pleine)', type: 'COMPLEX', price: 90, id: '6.haut-bro-22', multiple: true },

          { name: 'Dessin creux < 5cm', price: 10, type: 'SIMPLE', id: '6.haut-bro-7', multiple: true },
          { name: 'Dessin creux < 12cm', price: 17, type: 'SIMPLE', id: '6.haut-bro-8', multiple: true },
          { name: 'Dessin creux < 25cm', price: 25, type: 'SIMPLE', id: '6.haut-bro-9', multiple: true },
          { name: 'Dessin plein forme simple < 5cm', price: 35, type: 'SIMPLE', id: '6.haut-bro-10', multiple: true },
          { name: 'Dessin plein complexe < 5cm', price: 65, type: 'SIMPLE', id: '6.haut-bro-11', multiple: true },
          { name: 'Dessin plein forme simple < 12cm', price: 55, type: 'SIMPLE', id: '6.haut-bro-12', multiple: true },
          { name: 'Dessin plein complexe < 12cm', price: 130, type: 'SIMPLE', id: '6.haut-bro-13', multiple: true },

          { name: 'Dessin creux < 5cm', price: 15, type: 'COMPLEX', id: '6.haut-bro-14', multiple: true },
          { name: 'Dessin creux < 12cm', price: 22, type: 'COMPLEX', id: '6.haut-bro-15', multiple: true },
          { name: 'Dessin creux < 25cm', price: 30, type: 'COMPLEX', id: '6.haut-bro-16', multiple: true },
          { name: 'Dessin plein forme simple < 5cm', price: 42, type: 'COMPLEX', id: '6.haut-bro-17', multiple: true },
          { name: 'Dessin plein complexe < 5cm', price: 75, type: 'COMPLEX', id: '6.haut-bro-18', multiple: true },
          { name: 'Dessin plein forme simple < 12cm', price: 65, type: 'COMPLEX', id: '6.haut-bro-19', multiple: true },
          { name: 'Dessin plein complexe < 12cm', price: 145, type: 'COMPLEX', id: '6.haut-bro-20', multiple: true },
        ],
      },
      {
        name: 'Divers',
        items: [
          { name: 'Poignets (x2) : remplacement / changement', type: 'SIMPLE', price: 12, id: '6.haut-div-1' },
          { name: 'Coudières (x2) : ajout / remplacement', type: 'SIMPLE', price: 15, id: '6.haut-div-2' },
          { name: 'Épaulettes (x2) : ajout / remplacement / retrait', type: 'SIMPLE', price: 16, id: '6.haut-div-3' },
          { name: 'Manches (x2) : ajout / remplacement', type: 'SIMPLE', price: 25, id: '6.haut-div-4' },
          { name: 'Zip : ajout / remplacement / retrait', type: 'SIMPLE', price: 12, id: '6.haut-div-5', multiple: true },
          { name: 'Zip invisible', type: 'SIMPLE', price: 18, id: '6.haut-div-6', multiple: true },
          { name: 'Poche (x1) : ajout / remplacement', type: 'SIMPLE', price: 12, id: '6.haut-div-7', multiple: true },
          { name: 'Col : ajout / remplacement', type: 'SIMPLE', price: 15, id: '6.haut-div-8' },
          { name: 'Boutonnière (petite) : création', type: 'SIMPLE', price: 35, id: '6.haut-div-9', multiple: true, hidden: true },
          { name: 'Boutonnière (x1) : création', type: 'SIMPLE', price: 7, id: '6.haut-div-32', multiple: true },
          { name: 'Bouton (x1) : ajout / remplacement', type: 'SIMPLE', price: 1, id: '6.haut-div-10', multiple: true, mostCommonCategoryPosition: 3 },
          { name: 'Déchirure : réparation', type: 'SIMPLE', price: 6, id: '6.haut-div-11', multiple: true, mostCommonCategoryPosition: 1 },
          { name: 'Passant : ajout / remplacement', type: 'SIMPLE', price: 7, id: '6.haut-div-12', multiple: true },
          { name: 'Couture d’appoint / décousu - simple', type: 'SIMPLE', price: 4, id: '6.haut-div-13', multiple: true, mostCommonCategoryPosition: 2 },
          { name: 'Couture d’appoint / décousu - complexe', type: 'SIMPLE', price: 10, id: '6.haut-div-14', multiple: true },
          { name: 'Couture complexe avec empiècement', type: 'SIMPLE', price: 15, id: '6.haut-div-15', multiple: true },

          { name: 'Poignets (x2) : remplacement / changement', type: 'COMPLEX', price: 23, id: '6.haut-div-16' },
          { name: 'Coudières (x2) : ajout / remplacement', type: 'COMPLEX', price: 19, id: '6.haut-div-17' },
          { name: 'Épaulettes (x2) : ajout / remplacement', type: 'COMPLEX', price: 15, id: '6.haut-div-18' },
          { name: 'Manches (x2) : ajout / remplacement', type: 'COMPLEX', price: 41, id: '6.haut-div-19' },
          { name: 'Zip : ajout / remplacement / retrait', type: 'COMPLEX', price: 17, id: '6.haut-div-20', multiple: true },
          { name: 'Zip invisible', type: 'COMPLEX', price: 27, id: '6.haut-div-21', multiple: true },
          { name: 'Poche (x1) : ajout / remplacement', type: 'COMPLEX', price: 15, id: '6.haut-div-22', multiple: true },
          { name: 'Col : ajout / remplacement', type: 'COMPLEX', price: 28, id: '6.haut-div-23' },
          { name: 'Boutonnière (petite) : création', type: 'COMPLEX', price: 57, id: '6.haut-div-24', multiple: true, hidden: true },
          { name: 'Boutonnière (x1) : création', type: 'COMPLEX', price: 10, id: '6.haut-div-33', multiple: true },
          { name: 'Bouton (x1) : ajout / remplacement', type: 'COMPLEX', price: 2, id: '6.haut-div-25', multiple: true, mostCommonCategoryPosition: 3 },
          { name: 'Pince (x1) : ajout', type: 'COMPLEX', price: 17, id: '6.haut-div-31', multiple: true },
          { name: 'Déchirure : réparation', type: 'COMPLEX', price: 8, id: '6.haut-div-26', multiple: true, mostCommonCategoryPosition: 1 },
          { name: 'Passant : ajout / remplacement', type: 'COMPLEX', price: 10, id: '6.haut-div-27', multiple: true },
          { name: 'Couture d’appoint / décousu - simple', type: 'COMPLEX', price: 4, id: '6.haut-div-28', multiple: true, mostCommonCategoryPosition: 2 },
          { name: 'Couture d’appoint / décousu - complexe', type: 'COMPLEX', price: 10, id: '6.haut-div-29', multiple: true },
          { name: 'Couture complexe avec empiècement', type: 'COMPLEX', price: 18, id: '6.haut-div-30', multiple: true },
        ],
      },
    ],
  },


  {
    name: 'Pantalon',
    slug: 'pant',
    hasMostCommonCategory: true,
    showInPlugin: true,
    types: [
      { slug: 'SIMPLE', name: 'basique' },
      { slug: 'COMPLEX', name: 'tailleur' },
    ],
    categories: [
      {
        name: 'Cintrer',
        showInPlugin: true,
        items: [
          { name: 'Ceinture', type: 'SIMPLE', price: 32, showInPlugin: true, id: '6.pant-cint-1' },
          { name: 'Jambes (x2)', type: 'SIMPLE', price: 42, showInPlugin: true, id: '6.pant-cint-2' },
          { name: 'Bas de jambes (x2)', type: 'SIMPLE', price: 22, showInPlugin: true, id: '6.pant-cint-3', mostCommonCategoryPosition: 5, mostCommonCategoryLabel: 'Cintrer bas de jambes' },
          { name: 'Entrejambe', type: 'SIMPLE', price: 15, showInPlugin: true, id: '6.pant-cint-4' },

          { name: 'Ceinture', type: 'COMPLEX', price: 42, liningUnitPrice: 11, showInPlugin: true, id: '6.pant-cint-5' },
          { name: 'Jambes (x2)', type: 'COMPLEX', price: 45, liningUnitPrice: 9, showInPlugin: true, id: '6.pant-cint-6' },
          { name: 'Bas de jambes (x2)', type: 'COMPLEX', price: 24, liningUnitPrice: 8, showInPlugin: true, id: '6.pant-cint-7', mostCommonCategoryPosition: 5, mostCommonCategoryLabel: 'Cintrer bas de jambes' },
          { name: 'Entrejambe', type: 'COMPLEX', price: 22, liningUnitPrice: 9, showInPlugin: true, id: '6.pant-cint-8' },
        ],
      },
      {
        name: 'Élargir',
        showInPlugin: true,
        items: [
          { name: 'Ceinture', type: 'SIMPLE', price: 38, showInPlugin: true, id: '6.pant-ela-1' },
          { name: 'Jambes (x2)', type: 'SIMPLE', price: 47, showInPlugin: true, id: '6.pant-ela-2' },
          { name: 'Bas de jambes (x2)', type: 'SIMPLE', price: 27, showInPlugin: true, id: '6.pant-ela-3' },
          { name: 'Entrejambe', type: 'SIMPLE', price: 20, showInPlugin: true, id: '6.pant-ela-4' },

          { name: 'Ceinture', type: 'COMPLEX', price: 47, liningUnitPrice: 12, showInPlugin: true, id: '6.pant-ela-5' },
          { name: 'Jambes (x2)', type: 'COMPLEX', price: 50, liningUnitPrice: 10, showInPlugin: true, id: '6.pant-ela-6' },
          { name: 'Bas de jambes (x2)', type: 'COMPLEX', price: 27, liningUnitPrice: 9, showInPlugin: true, id: '6.pant-ela-7' },
          { name: 'Entrejambe', type: 'COMPLEX', price: 27, liningUnitPrice: 10, showInPlugin: true, id: '6.pant-ela-8' },
        ],
      },
      {
        name: 'Ourlets',
        showInPlugin: true,
        items: [
          { name: 'Ourlet simple (x2)', type: 'SIMPLE', price: 12, showInPlugin: true, id: '6.pant-rac-1', mostCommonCategoryPosition: 1 },
          { name: 'Ourlet revers (x2)', type: 'SIMPLE', price: 15, showInPlugin: true, id: '6.pant-rac-2' },

          { name: 'Ourlet simple (x2)', type: 'COMPLEX', price: 17, liningUnitPrice: 7, showInPlugin: true, id: '6.pant-rac-3', mostCommonCategoryPosition: 1 },
          { name: 'Ourlet revers (x2)', type: 'COMPLEX', price: 20, liningUnitPrice: 9, showInPlugin: true, id: '6.pant-rac-4' },
        ],
      },
      {
        name: 'Rallonger',
        showInPlugin: true,
        items: [
          { name: 'Jambes (x2)', type: 'SIMPLE', price: 15, showInPlugin: true, id: '6.pant-ral-1' },

          { name: 'Jambes (x2)', type: 'COMPLEX', price: 20, liningUnitPrice: 9, showInPlugin: true, id: '6.pant-ral-2' },
          { name: 'Ourlet (x2) : suppression des revers', type: 'COMPLEX', price: 17, liningUnitPrice: 7, showInPlugin: true, id: '6.pant-ral-3' },
        ],
      },
      {
        name: 'Broderie main',
        items: [
          { name: 'Initiales', type: 'SIMPLE', price: 15, id: '6.pant-bro-1', multiple: true },
          { name: 'Un mot (10 lettres - petite police)', type: 'SIMPLE', price: 22, id: '6.pant-bro-2', multiple: true },
          { name: 'Un mot (10 lettres - grosse police)', type: 'SIMPLE', price: 45, id: '6.pant-bro-3', multiple: true },
          { name: 'Un mot (10 lettres - grosse police pleine)', type: 'SIMPLE', price: 80, id: '6.pant-bro-21', multiple: true },

          { name: 'Initiales', type: 'COMPLEX', price: 17, id: '6.pant-bro-4', multiple: true },
          { name: 'Un mot (10 lettres - petite police)', type: 'COMPLEX', price: 25, id: '6.pant-bro-5', multiple: true },
          { name: 'Un mot (10 lettres - grosse police)', type: 'COMPLEX', price: 45, id: '6.pant-bro-6', multiple: true },
          { name: 'Un mot (10 lettres - grosse police pleine)', type: 'COMPLEX', price: 200, id: '6.pant-bro-22', multiple: true },

          { name: 'Dessin creux < 5cm', price: 10, type: 'SIMPLE', id: '6.pant-bro-7', multiple: true },
          { name: 'Dessin creux < 12cm', price: 17, type: 'SIMPLE', id: '6.pant-bro-8', multiple: true },
          { name: 'Dessin creux < 25cm', price: 25, type: 'SIMPLE', id: '6.pant-bro-9', multiple: true },
          { name: 'Dessin plein forme simple < 5cm', price: 35, type: 'SIMPLE', id: '6.pant-bro-10', multiple: true },
          { name: 'Dessin plein complexe < 5cm', price: 65, type: 'SIMPLE', id: '6.pant-bro-11', multiple: true },
          { name: 'Dessin plein forme simple < 12cm', price: 55, type: 'SIMPLE', id: '6.pant-bro-12', multiple: true },
          { name: 'Dessin plein complexe < 12cm', price: 130, type: 'SIMPLE', id: '6.pant-bro-13', multiple: true },

          { name: 'Dessin creux < 5cm', price: 15, type: 'COMPLEX', id: '6.pant-bro-14', multiple: true },
          { name: 'Dessin creux < 12cm', price: 22, type: 'COMPLEX', id: '6.pant-bro-15', multiple: true },
          { name: 'Dessin creux < 25cm', price: 30, type: 'COMPLEX', id: '6.pant-bro-16', multiple: true },
          { name: 'Dessin plein forme simple < 5cm', price: 42, type: 'COMPLEX', id: '6.pant-bro-17', multiple: true },
          { name: 'Dessin plein complexe < 5cm', price: 75, type: 'COMPLEX', id: '6.pant-bro-18', multiple: true },
          { name: 'Dessin plein forme simple < 12cm', price: 65, type: 'COMPLEX', id: '6.pant-bro-19', multiple: true },
          { name: 'Dessin plein complexe < 12cm', price: 145, type: 'COMPLEX', id: '6.pant-bro-20', multiple: true },
        ],
      },
      {
        name: 'Divers',
        items: [
          { name: 'Zip : ajout / remplacement / retrait', type: 'SIMPLE', price: 17, id: '6.pant-div-1', multiple: true, mostCommonCategoryPosition: 2 },
          { name: 'Zip invisible', type: 'SIMPLE', price: 25, id: '6.pant-div-2', multiple: true },
          { name: 'Genouillères (x2) : ajout / remplacement', type: 'SIMPLE', price: 17, id: '6.pant-div-3' },
          { name: 'Poche (x1) : remplacement', type: 'SIMPLE', price: 14, id: '6.pant-div-4', multiple: true },
          { name: 'Poche (x1) : ajout', type: 'SIMPLE', price: 20, id: '6.pant-div-5', multiple: true },
          { name: 'Bouton (x1) : ajout / remplacement', type: 'SIMPLE', price: 3, id: '6.pant-div-6', multiple: true },
          { name: 'Déchirure : réparation', type: 'SIMPLE', price: 10, id: '6.pant-div-7', multiple: true, mostCommonCategoryPosition: 3 },
          { name: 'Passant : ajout / remplacement', type: 'SIMPLE', price: 7, id: '6.pant-div-8', multiple: true },
          { name: 'Pince (x1) : ajout', type: 'SIMPLE', price: 14, id: '6.pant-div-9', multiple: true },
          { name: 'Couture d’appoint / décousu - simple', type: 'SIMPLE', price: 4, id: '6.pant-div-10', multiple: true, mostCommonCategoryPosition: 4 },
          { name: 'Couture d’appoint / décousu - complexe', type: 'SIMPLE', price: 10, id: '6.pant-div-11', multiple: true },
          { name: 'Couture complexe avec empiècement', type: 'SIMPLE', price: 22, id: '6.pant-div-12', multiple: true },

          { name: 'Zip : ajout / remplacement / retrait', type: 'COMPLEX', price: 19, liningUnitPrice: 7, id: '6.pant-div-13', multiple: true, mostCommonCategoryPosition: 2 },
          { name: 'Zip invisible', type: 'COMPLEX', price: 27, liningUnitPrice: 12, id: '6.pant-div-14', multiple: true },
          { name: 'Poche : remplacement', type: 'COMPLEX', price: 15, id: '6.pant-div-15', multiple: true },
          { name: 'Poche : ajout', type: 'COMPLEX', price: 23, id: '6.pant-div-16', multiple: true },
          { name: 'Boutonnière (petite) : création', type: 'COMPLEX', price: 57, liningUnitPrice: 15, id: '6.pant-div-17', multiple: true },
          { name: 'Bouton (x1) : ajout / remplacement', type: 'COMPLEX', price: 3, id: '6.pant-div-18', multiple: true },
          { name: 'Déchirure : réparation', type: 'COMPLEX', price: 10, id: '6.pant-div-19', multiple: true, mostCommonCategoryPosition: 3 },
          { name: 'Passant : ajout / remplacement', type: 'COMPLEX', price: 10, liningUnitPrice: 2, id: '6.pant-div-20', multiple: true },
          { name: 'Pince (x1) : ajout', type: 'COMPLEX', price: 20, liningUnitPrice: 4, id: '6.pant-div-21', multiple: true },
          { name: 'Couture d’appoint / décousu - simple', type: 'COMPLEX', price: 4, liningUnitPrice: 1, id: '6.pant-div-22', multiple: true, mostCommonCategoryPosition: 4 },
          { name: 'Couture d’appoint / décousu - complexe', type: 'COMPLEX', price: 10, liningUnitPrice: 2, id: '6.pant-div-23', multiple: true },
          { name: 'Couture complexe avec empiècement', type: 'COMPLEX', price: 22, liningUnitPrice: 4, id: '6.pant-div-24', multiple: true },
        ],
      },
    ],
  },


  {
    name: 'Jupe',
    slug: 'jupe',
    hasMostCommonCategory: true,
    showInPlugin: true,
    types: [
      { slug: 'SIMPLE', name: 'simple' },
      { slug: 'COMPLEX', name: 'tailleur & soirée' },
    ],
    categories: [
      {
        name: 'Cintrer',
        showInPlugin: true,
        items: [
          { name: 'Ceinture', type: 'SIMPLE', price: 25, showInPlugin: true, id: '6.jupe-cin-1', mostCommonCategoryPosition: 5 },
          { name: 'Côtés (x2)', type: 'SIMPLE', price: 23, liningUnitPrice: 4, showInPlugin: true, id: '6.jupe-cin-2' },

          { name: 'Ceinture', type: 'COMPLEX', price: 38, liningUnitPrice: 11, showInPlugin: true, id: '6.jupe-cin-3', mostCommonCategoryPosition: 5 },
          { name: 'Côtés (x2)', type: 'COMPLEX', price: 38, liningUnitPrice: 9, showInPlugin: true, id: '6.jupe-cin-4' },
        ],
      },
      {
        name: 'Élargir',
        showInPlugin: true,
        items: [
          { name: 'Ceinture', type: 'SIMPLE', price: 29, showInPlugin: true, id: '6.jupe-ela-1' },
          { name: 'Côtés (x2)', type: 'SIMPLE', price: 27, showInPlugin: true, id: '6.jupe-ela-2' },

          { name: 'Ceinture', type: 'COMPLEX', price: 29, liningUnitPrice: 29, showInPlugin: true, id: '6.jupe-ela-3' },
          { name: 'Côtés (x2)', type: 'COMPLEX', price: 41, liningUnitPrice: 29, showInPlugin: true, id: '6.jupe-ela-4' },
        ],
      },
      {
        name: 'Raccourcir',
        showInPlugin: true,
        items: [
          { name: 'Ourlet simple', type: 'SIMPLE', price: 12, liningUnitPrice: 4, showInPlugin: true, id: '6.jupe-rac-1', mostCommonCategoryPosition: 1 },
          { name: 'Ourlet ample', type: 'SIMPLE', price: 22, liningUnitPrice: 7, showInPlugin: true, id: '6.jupe-rac-2' },
          { name: 'Ourlet revers', type: 'SIMPLE', price: 15, liningUnitPrice: 5, showInPlugin: true, id: '6.jupe-rac-3' },

          { name: 'Ourlet simple', type: 'COMPLEX', price: 36, liningUnitPrice: 8, showInPlugin: true, id: '6.jupe-rac-4', mostCommonCategoryPosition: 1 },
          { name: 'Ourlet ample', type: 'COMPLEX', price: 46, liningUnitPrice: 10, showInPlugin: true, id: '6.jupe-rac-5' },
          { name: 'Ourlet revers', type: 'COMPLEX', price: 38, liningUnitPrice: 9, showInPlugin: true, id: '6.jupe-rac-6' },
        ],
      },
      {
        name: 'Rallonger',
        showInPlugin: true,
        items: [
          { name: 'Longueur simple', type: 'SIMPLE', price: 17, showInPlugin: true, id: '6.jupe-ral-1' },
          { name: 'Longueur ample', type: 'SIMPLE', price: 27, showInPlugin: true, id: '6.jupe-ral-2' },

          { name: 'Longueur simple', type: 'COMPLEX', price: 41, liningUnitPrice: 7, showInPlugin: true, id: '6.jupe-ral-3' },
          { name: 'Longueur ample', type: 'COMPLEX', price: 51, liningUnitPrice: 6, showInPlugin: true, id: '6.jupe-ral-4' },
        ],
      },
      {
        name: 'Broderie main',
        items: [
          { name: 'Initiales', type: 'SIMPLE', price: 15, id: '6.jupe-bro-1', multiple: true },
          { name: 'Un mot (10 lettres - petite police)', type: 'SIMPLE', price: 22, id: '6.jupe-bro-2', multiple: true },
          { name: 'Un mot (10 lettres - grosse police)', type: 'SIMPLE', price: 45, id: '6.jupe-bro-3', multiple: true },

          { name: 'Initiales', type: 'COMPLEX', price: 17, id: '6.jupe-bro-4', multiple: true },
          { name: 'Un mot (10 lettres - petite police)', type: 'COMPLEX', price: 25, id: '6.jupe-bro-5', multiple: true },
          { name: 'Un mot (10 lettres - grosse police)', type: 'COMPLEX', price: 45, id: '6.jupe-bro-6', multiple: true },

          { name: 'Dessin creux < 5cm', price: 10, type: 'SIMPLE', id: '6.jupe-bro-7', multiple: true },
          { name: 'Dessin creux < 12cm', price: 17, type: 'SIMPLE', id: '6.jupe-bro-8', multiple: true },
          { name: 'Dessin creux < 25cm', price: 25, type: 'SIMPLE', id: '6.jupe-bro-9', multiple: true },
          { name: 'Dessin plein forme simple < 5cm', price: 35, type: 'SIMPLE', id: '6.jupe-bro-10', multiple: true },
          { name: 'Dessin plein complexe < 5cm', price: 65, type: 'SIMPLE', id: '6.jupe-bro-11', multiple: true },
          { name: 'Dessin plein forme simple < 12cm', price: 55, type: 'SIMPLE', id: '6.jupe-bro-12', multiple: true },
          { name: 'Dessin plein complexe < 12cm', price: 130, type: 'SIMPLE', id: '6.jupe-bro-13', multiple: true },

          { name: 'Dessin creux < 5cm', price: 15, type: 'COMPLEX', id: '6.jupe-bro-14', multiple: true },
          { name: 'Dessin creux < 12cm', price: 22, type: 'COMPLEX', id: '6.jupe-bro-15', multiple: true },
          { name: 'Dessin creux < 25cm', price: 30, type: 'COMPLEX', id: '6.jupe-bro-16', multiple: true },
          { name: 'Dessin plein forme simple < 5cm', price: 42, type: 'COMPLEX', id: '6.jupe-bro-17', multiple: true },
          { name: 'Dessin plein complexe < 5cm', price: 75, type: 'COMPLEX', id: '6.jupe-bro-18', multiple: true },
          { name: 'Dessin plein forme simple < 12cm', price: 65, type: 'COMPLEX', id: '6.jupe-bro-19', multiple: true },
          { name: 'Dessin plein complexe < 12cm', price: 145, type: 'COMPLEX', id: '6.jupe-bro-20', multiple: true },
        ],
      },
      {
        name: 'Divers',
        items: [
          { name: 'Doublure : ajout / remplacement', type: 'SIMPLE', price: 35, id: '6.jupe-div-1' },
          { name: 'Zip : ajout / remplacement', type: 'SIMPLE', price: 15, liningUnitPrice: 5, id: '6.jupe-div-2', multiple: true, mostCommonCategoryPosition: 2 },
          { name: 'Zip invisible', type: 'SIMPLE', price: 22, liningUnitPrice: 7, id: '6.jupe-div-3', multiple: true },
          { name: 'Fente : ajout / retrait', type: 'SIMPLE', price: 16, liningUnitPrice: 5, id: '6.jupe-div-4', multiple: true },
          { name: 'Pince (x1) : ajout', type: 'SIMPLE', price: 14, id: '6.jupe-div-5', multiple: true },
          { name: 'Poche (x1) : remplacement', type: 'SIMPLE', price: 14, id: '6.jupe-div-6', multiple: true },
          { name: 'Poche (x1) : ajout', type: 'SIMPLE', price: 20, id: '6.jupe-div-7', multiple: true },
          { name: 'Boutonnière (petite) : création', type: 'SIMPLE', price: 40, id: '6.jupe-div-8', multiple: true, hidden: true },
          { name: 'Boutonnière (x1) : création', type: 'SIMPLE', price: 7, id: '6.jupe-div-29', multiple: true },
          { name: 'Bouton (x1) : ajout / remplacement', type: 'SIMPLE', price: 3, id: '6.jupe-div-9', multiple: true },
          { name: 'Déchirure : réparation', type: 'SIMPLE', price: 8, id: '6.jupe-div-10', multiple: true, mostCommonCategoryPosition: 3 },
          { name: 'Passant : ajout / remplacement', type: 'SIMPLE', price: 7, id: '6.jupe-div-11', multiple: true },
          { name: 'Couture d’appoint / décousu - simple', type: 'SIMPLE', price: 4, id: '6.jupe-div-12', multiple: true, mostCommonCategoryPosition: 4 },
          { name: 'Couture d’appoint / décousu - complexe', type: 'SIMPLE', price: 10, id: '6.jupe-div-13', multiple: true },
          { name: 'Couture complexe avec empiècement', type: 'SIMPLE', price: 22, id: '6.jupe-div-14', multiple: true },

          { name: 'Doublure : ajout / remplacement', type: 'COMPLEX', price: 50, id: '6.jupe-div-15' },
          { name: 'Zip : ajout / remplacement', type: 'COMPLEX', price: 19, liningUnitPrice: 7, id: '6.jupe-div-16', multiple: true, mostCommonCategoryPosition: 2 },
          { name: 'Zip invisible', type: 'COMPLEX', price: 32, liningUnitPrice: 12, id: '6.jupe-div-17', multiple: true },
          { name: 'Fente : ajout / remplacement / retrait', type: 'COMPLEX', price: 23, liningUnitPrice: 23, id: '6.jupe-div-18', multiple: true },
          { name: 'Pince (x1) : ajout', type: 'COMPLEX', price: 20, liningUnitPrice: 4, id: '6.jupe-div-19', multiple: true },
          { name: 'Poche (x1) : changement de doublure', type: 'COMPLEX', price: 20, liningUnitPrice: 20, id: '6.jupe-div-20', multiple: true },
          { name: 'Poche (x1) : ajout / remplacement', type: 'COMPLEX', price: 25, id: '6.jupe-div-21', multiple: true },
          { name: 'Boutonnière (petite) : création', type: 'COMPLEX', price: 48, liningUnitPrice: 12, id: '6.jupe-div-22', multiple: true, hidden: true },
          { name: 'Boutonnière (x1) : création', type: 'COMPLEX', price: 10, liningUnitPrice: 1, id: '6.jupe-div-30', multiple: true },
          { name: 'Bouton (x1) : ajout / remplacement', type: 'COMPLEX', price: 3, id: '6.jupe-div-23', multiple: true },
          { name: 'Déchirure : réparation', type: 'COMPLEX', price: 10, id: '6.jupe-div-24', multiple: true, mostCommonCategoryPosition: 3 },
          { name: 'Passant : ajout / remplacement', type: 'COMPLEX', price: 10, liningUnitPrice: 7, id: '6.jupe-div-25', multiple: true },
          { name: 'Couture d’appoint / décousu - simple', type: 'COMPLEX', price: 4, liningUnitPrice: 1, id: '6.jupe-div-26', multiple: true, mostCommonCategoryPosition: 4 },
          { name: 'Couture d’appoint / décousu - complexe', type: 'COMPLEX', price: 10, liningUnitPrice: 2, id: '6.jupe-div-27', multiple: true },
          { name: 'Couture complexe avec empiècement', type: 'COMPLEX', price: 23, liningUnitPrice: 4, id: '6.jupe-div-28', multiple: true },
        ],
      },
    ],
  },


  {
    name: 'Robe',
    slug: 'robe',
    hasMostCommonCategory: true,
    showInPlugin: true,
    types: [
      { slug: 'SIMPLE', name: 'simple' },
      { slug: 'COMPLEX', name: 'habillée' },
    ],
    categories: [
      {
        name: 'Cintrer',
        showInPlugin: true,
        items: [
          { name: 'Côtés (x2)', type: 'SIMPLE', price: 28, liningUnitPrice: 12, showInPlugin: true, id: '6.robe-cin-1' },
          { name: 'Épaules (x2)', type: 'SIMPLE', price: 20, liningUnitPrice: 3, showInPlugin: true, id: '6.robe-cin-2' },
          { name: 'Manches (x2 - sans boutonnière)', type: 'SIMPLE', price: 20, showInPlugin: true, id: '6.robe-cin-3' },
          { name: 'Manches (x2 - boutonnière)', type: 'SIMPLE', price: 35, showInPlugin: true, id: '6.robe-cin-4' },
          { name: 'Bustier / taille', type: 'SIMPLE', price: 29, liningUnitPrice: 4, showInPlugin: true, id: '6.robe-cin-5', mostCommonCategoryPosition: 5, mostCommonCategoryLabel: 'Cintrer taille' },
          { name: 'Dos', type: 'SIMPLE', price: 21, liningUnitPrice: 4, showInPlugin: true, id: '6.robe-cin-6' },

          { name: 'Côtés (x2)', type: 'COMPLEX', price: 33, liningUnitPrice: 12, showInPlugin: true, id: '6.robe-cin-7' },
          { name: 'Épaules (x2)', type: 'COMPLEX', price: 30, liningUnitPrice: 8, showInPlugin: true, id: '6.robe-cin-8' },
          { name: 'Manches (x2 - sans boutonnière)', type: 'COMPLEX', price: 35, showInPlugin: true, id: '6.robe-cin-9' },
          { name: 'Manches (x2 - boutonnière)', type: 'COMPLEX', price: 40, showInPlugin: true, id: '6.robe-cin-10' },
          { name: 'Bustier / taille', type: 'COMPLEX', price: 39, liningUnitPrice: 8, showInPlugin: true, id: '6.robe-cin-11', mostCommonCategoryPosition: 5, mostCommonCategoryLabel: 'Cintrer taille' },
          { name: 'Dos', type: 'COMPLEX', price: 33, liningUnitPrice: 8, showInPlugin: true, id: '6.robe-cin-12' },
        ],
      },
      {
        name: 'Élargir',
        showInPlugin: true,
        items: [
          { name: 'Côtés (x2)', type: 'SIMPLE', price: 30, liningUnitPrice: 12, showInPlugin: true, id: '6.robe-ela-1' },
          { name: 'Épaules (x2)', type: 'SIMPLE', price: 22, liningUnitPrice: 4, showInPlugin: true, id: '6.robe-ela-2' },
          { name: 'Manches (x2 - sans boutonnière)', type: 'SIMPLE', price: 25, showInPlugin: true, id: '6.robe-ela-3' },
          { name: 'Manches (x2 - boutonnière)', type: 'SIMPLE', price: 40, showInPlugin: true, id: '6.robe-ela-4' },
          { name: 'Bustier / taille', type: 'SIMPLE', price: 34, liningUnitPrice: 5, showInPlugin: true, id: '6.robe-ela-5' },
          { name: 'Dos', type: 'SIMPLE', price: 26, liningUnitPrice: 5, showInPlugin: true, id: '6.robe-ela-6' },

          { name: 'Côtés (x2)', type: 'COMPLEX', price: 35, liningUnitPrice: 12, showInPlugin: true, id: '6.robe-ela-7' },
          { name: 'Épaules (x2)', type: 'COMPLEX', price: 32, liningUnitPrice: 8, showInPlugin: true, id: '6.robe-ela-8' },
          { name: 'Manches (x2 - sans boutonnière)', type: 'COMPLEX', price: 37, showInPlugin: true, id: '6.robe-ela-9' },
          { name: 'Manches (x2 - boutonnière)', type: 'COMPLEX', price: 43, showInPlugin: true, id: '6.robe-ela-10' },
          { name: 'Bustier / taille', type: 'COMPLEX', price: 45, liningUnitPrice: 8, showInPlugin: true, id: '6.robe-ela-11' },
          { name: 'Dos', type: 'COMPLEX', price: 35, liningUnitPrice: 8, showInPlugin: true, id: '6.robe-ela-12' },
        ],
      },
      {
        name: 'Raccourcir',
        showInPlugin: true,
        items: [
          { name: 'Ourlet simple', type: 'SIMPLE', price: 12, liningUnitPrice: 4, showInPlugin: true, id: '6.robe-rac-1', mostCommonCategoryPosition: 1 },
          { name: 'Ourlet ample', type: 'SIMPLE', price: 22, liningUnitPrice: 7, showInPlugin: true, id: '6.robe-rac-2' },
          { name: 'Ourlet revers', type: 'SIMPLE', price: 15, liningUnitPrice: 5, showInPlugin: true, id: '6.robe-rac-3' },
          { name: 'Manches (x2 - sans boutonnière)', type: 'SIMPLE', price: 12, showInPlugin: true, id: '6.robe-rac-4' },
          { name: 'Manches (x2 - boutonnière)', type: 'SIMPLE', price: 22, showInPlugin: true, id: '6.robe-rac-5' },
          { name: 'Bretelles (x2)', type: 'SIMPLE', price: 8, showInPlugin: true, id: '6.robe-rac-6' },

          { name: 'Ourlet simple', type: 'COMPLEX', price: 31, liningUnitPrice: 13, showInPlugin: true, id: '6.robe-rac-7', mostCommonCategoryPosition: 1 },
          { name: 'Ourlet à volants', type: 'COMPLEX', price: 41, liningUnitPrice: 15, showInPlugin: true, id: '6.robe-rac-8' },
          { name: 'Ourlet revers', type: 'COMPLEX', price: 39, liningUnitPrice: 13, showInPlugin: true, id: '6.robe-rac-9' },
          { name: 'Manches (x2 - sans boutonnière)', type: 'COMPLEX', price: 12, showInPlugin: true, id: '6.robe-rac-10' },
          { name: 'Manches (x2 - boutonnière)', type: 'COMPLEX', price: 32, showInPlugin: true, id: '6.robe-rac-11' },
          { name: 'Bretelles (x2)', type: 'COMPLEX', price: 20, showInPlugin: true, id: '6.robe-rac-12' },
        ],
      },
      {
        name: 'Rallonger',
        showInPlugin: true,
        items: [
          { name: 'Longueur simple', type: 'SIMPLE', price: 17, liningUnitPrice: 5, showInPlugin: true, id: '6.robe-ral-1' },
          { name: 'Longueur ample', type: 'SIMPLE', price: 28, liningUnitPrice: 8, showInPlugin: true, id: '6.robe-ral-2' },
          { name: 'Manches (x2 - sans boutonnière)', type: 'SIMPLE', price: 17, showInPlugin: true, id: '6.robe-ral-4' },
          { name: 'Manches (x2 - boutonnière)', type: 'SIMPLE', price: 27, showInPlugin: true, id: '6.robe-ral-5' },
          { name: 'Bretelles (x2)', type: 'SIMPLE', price: 13, liningUnitPrice: 4, showInPlugin: true, id: '6.robe-ral-6' },

          { name: 'Longueur simple', type: 'COMPLEX', price: 31, liningUnitPrice: 13, showInPlugin: true, id: '6.robe-ral-7' },
          { name: 'Longueur à volants', type: 'COMPLEX', price: 41, liningUnitPrice: 15, showInPlugin: true, id: '6.robe-ral-8' },
          { name: 'Ourlets (x2) : suppression des revers', type: 'COMPLEX', price: 41, liningUnitPrice: 11, showInPlugin: true, id: '6.robe-ral-9' },
          { name: 'Manches (x2 - sans boutonnière)', type: 'COMPLEX', price: 12, showInPlugin: true, id: '6.robe-ral-10' },
          { name: 'Manches (x2 - boutonnière)', type: 'COMPLEX', price: 32, showInPlugin: true, id: '6.robe-ral-11' },
          { name: 'Bretelles (x2)', type: 'COMPLEX', price: 20, showInPlugin: true, id: '6.robe-ral-12' },
        ],
      },
      {
        name: 'Broderie main',
        items: [
          { name: 'Initiales', type: 'SIMPLE', price: 15, id: '6.robe-bro-1', multiple: true },
          { name: 'Un mot (10 lettres - petite police)', type: 'SIMPLE', price: 22, id: '6.robe-bro-2', multiple: true },
          { name: 'Un mot (10 lettres - grosse police)', type: 'SIMPLE', price: 45, id: '6.robe-bro-3', multiple: true },

          { name: 'Initiales', type: 'COMPLEX', price: 17, id: '6.robe-bro-4', multiple: true },
          { name: 'Un mot (10 lettres - petite police)', type: 'COMPLEX', price: 25, id: '6.robe-bro-5', multiple: true },
          { name: 'Un mot (10 lettres - grosse police)', type: 'COMPLEX', price: 49, id: '6.robe-bro-6', multiple: true },

          { name: 'Dessin creux < 5cm', price: 10, type: 'SIMPLE', id: '6.robe-bro-7', multiple: true },
          { name: 'Dessin creux < 12cm', price: 17, type: 'SIMPLE', id: '6.robe-bro-8', multiple: true },
          { name: 'Dessin creux < 25cm', price: 25, type: 'SIMPLE', id: '6.robe-bro-9', multiple: true },
          { name: 'Dessin plein forme simple < 5cm', price: 35, type: 'SIMPLE', id: '6.robe-bro-10', multiple: true },
          { name: 'Dessin plein complexe < 5cm', price: 65, type: 'SIMPLE', id: '6.robe-bro-11', multiple: true },
          { name: 'Dessin plein forme simple < 12cm', price: 55, type: 'SIMPLE', id: '6.robe-bro-12', multiple: true },
          { name: 'Dessin plein complexe < 12cm', price: 130, type: 'SIMPLE', id: '6.robe-bro-13', multiple: true },

          { name: 'Dessin creux < 5cm', price: 15, type: 'COMPLEX', id: '6.robe-bro-14', multiple: true },
          { name: 'Dessin creux < 12cm', price: 22, type: 'COMPLEX', id: '6.robe-bro-15', multiple: true },
          { name: 'Dessin creux < 25cm', price: 30, type: 'COMPLEX', id: '6.robe-bro-16', multiple: true },
          { name: 'Dessin plein forme simple < 5cm', price: 42, type: 'COMPLEX', id: '6.robe-bro-17', multiple: true },
          { name: 'Dessin plein complexe < 5cm', price: 75, type: 'COMPLEX', id: '6.robe-bro-18', multiple: true },
          { name: 'Dessin plein forme simple < 12cm', price: 65, type: 'COMPLEX', id: '6.robe-bro-19', multiple: true },
          { name: 'Dessin plein complexe < 12cm', price: 145, type: 'COMPLEX', id: '6.robe-bro-20', multiple: true },
        ],
      },
      {
        name: 'Divers',
        items: [
          { name: 'Doublure : ajout / remplacement', type: 'SIMPLE', price: 50, id: '6.robe-div-1' },
          { name: 'Zip : ajout / remplacement', type: 'SIMPLE', price: 22, liningUnitPrice: 10, id: '6.robe-div-2', multiple: true, mostCommonCategoryPosition: 2 },
          { name: 'Zip invisible', type: 'SIMPLE', price: 29, liningUnitPrice: 11, id: '6.robe-div-3', multiple: true },
          { name: 'Bretelles (x2) : ajout / remplacement', type: 'SIMPLE', price: 12, id: '6.robe-div-4' },
          { name: 'Fente : ajout / retrait', type: 'SIMPLE', price: 25, liningUnitPrice: 5, id: '6.robe-div-5', multiple: true },
          { name: 'Pince (x1) : ajout', type: 'SIMPLE', price: 20, id: '6.robe-div-6', multiple: true },
          { name: 'Poche (x1) : changement de doublure', type: 'SIMPLE', price: 14, id: '6.robe-div-7', multiple: true },
          { name: 'Poche (x1) : ajout / remplacement', type: 'SIMPLE', price: 20, id: '6.robe-div-8', multiple: true },
          { name: 'Boutonnière (petite) : création', type: 'SIMPLE', price: 40, id: '6.robe-div-9', multiple: true, hidden: true },
          { name: 'Boutonnière (x1) : création', type: 'SIMPLE', price: 7, id: '6.robe-div-33', multiple: true },
          { name: 'Bouton (x1) : ajout / remplacement', type: 'SIMPLE', price: 3, id: '6.robe-div-10', multiple: true },
          { name: 'Déchirure : réparation', type: 'SIMPLE', price: 8, id: '6.robe-div-11', multiple: true, mostCommonCategoryPosition: 3 },
          { name: 'Passant : ajout / remplacement', type: 'SIMPLE', price: 7, id: '6.robe-div-12', multiple: true },
          { name: 'Couture d’appoint / décousu - simple', type: 'SIMPLE', price: 4, id: '6.robe-div-13', multiple: true, mostCommonCategoryPosition: 4 },
          { name: 'Couture d’appoint / décousu - complexe', type: 'SIMPLE', price: 10, id: '6.robe-div-14', multiple: true },
          { name: 'Couture complexe avec empiècement', type: 'SIMPLE', price: 22, id: '6.robe-div-15', multiple: true },

          { name: 'Doublure : ajout / remplacement', type: 'COMPLEX', price: 69, id: '6.robe-div-16' },
          { name: 'Zip : ajout / remplacement', type: 'COMPLEX', price: 35, liningUnitPrice: 13, id: '6.robe-div-17', multiple: true, mostCommonCategoryPosition: 2 },
          { name: 'Zip invisible', type: 'COMPLEX', price: 43, liningUnitPrice: 15, id: '6.robe-div-18', multiple: true },
          { name: 'Bretelles (x2) : ajout / remplacement / retrait', type: 'COMPLEX', price: 20, id: '6.robe-div-19' },
          { name: 'Ceinture : ajout / remplacement', type: 'COMPLEX', price: 32, id: '6.robe-div-20', multiple: true },
          { name: 'Fente : ajout / remplacement / retrait', type: 'COMPLEX', price: 25, liningUnitPrice: 6, id: '6.robe-div-21', multiple: true },
          { name: 'Pince (x1) : ajout', type: 'COMPLEX', price: 20, liningUnitPrice: 20, id: '6.robe-div-22', multiple: true },
          { name: 'Poche (x1) : changement de doublure', type: 'COMPLEX', price: 20, id: '6.robe-div-23', multiple: true },
          { name: 'Poche (x1) : ajout / remplacement', type: 'COMPLEX', price: 25, id: '6.robe-div-24', multiple: true },
          { name: 'Boutonnière (x1) : création', type: 'COMPLEX', price: 10, liningUnitPrice: 1, id: '6.robe-div-34', multiple: true },
          { name: 'Bouton (x1) : ajout / remplacement', type: 'COMPLEX', price: 4, id: '6.robe-div-26', multiple: true },
          { name: 'Bouton pression (x1) : ajout / remplacement', type: 'COMPLEX', price: 7, id: '6.robe-div-27', multiple: true },
          { name: 'Déchirure : réparation', type: 'COMPLEX', price: 12, id: '6.robe-div-28', multiple: true, mostCommonCategoryPosition: 3 },
          { name: 'Passant : ajout / remplacement', type: 'COMPLEX', price: 14, id: '6.robe-div-29', multiple: true },
          { name: 'Couture d’appoint / décousu - simple', type: 'COMPLEX', price: 7, id: '6.robe-div-30', multiple: true, mostCommonCategoryPosition: 4 },
          { name: 'Couture d’appoint / décousu - complexe', type: 'COMPLEX', price: 14, id: '6.robe-div-31', multiple: true },
          { name: 'Couture complexe avec empiècement', type: 'COMPLEX', price: 20, id: '6.robe-div-32', multiple: true },
        ],
      },
    ],
  },


  {
    name: 'Pull & gilet',
    slug: 'pull',
    hasMostCommonCategory: true,
    showInPlugin: true,
    types: [
      { slug: 'SIMPLE', name: 'basique' },
      { slug: 'COMPLEX', name: 'laine & cachemire' },
    ],
    categories: [
      {
        name: 'Cintrer',
        showInPlugin: true,
        items: [
          { name: 'Côtés (x2)', type: 'SIMPLE', price: 16, showInPlugin: true, id: '6.pull-cin-1' },
          { name: 'Épaules (x2)', type: 'SIMPLE', price: 18, showInPlugin: true, id: '6.pull-cin-2' },
          { name: 'Manches (x2 - sans boutonnière)', type: 'SIMPLE', price: 21, showInPlugin: true, id: '6.pull-cin-3' },
          { name: 'Taille', type: 'SIMPLE', price: 18, showInPlugin: true, id: '6.pull-cin-4' },
          { name: 'Dos', type: 'SIMPLE', price: 11, showInPlugin: true, id: '6.pull-cin-5', mostCommonCategoryPosition: 4, mostCommonCategoryLabel: 'Cintrer dos' },

          { name: 'Côtés (x2)', type: 'COMPLEX', price: 40, showInPlugin: true, id: '6.pull-cin-6' },
          { name: 'Épaules (x2)', type: 'COMPLEX', price: 45, showInPlugin: true, id: '6.pull-cin-7' },
          { name: 'Manches (x2 - sans boutonnière)', type: 'COMPLEX', price: 52, showInPlugin: true, id: '6.pull-cin-8' },
          { name: 'Taille', type: 'COMPLEX', price: 45, showInPlugin: true, id: '6.pull-cin-9' },
          { name: 'Dos', type: 'COMPLEX', price: 27, showInPlugin: true, id: '6.pull-cin-10', mostCommonCategoryPosition: 4, mostCommonCategoryLabel: 'Cintrer dos' },
        ],
      },
      {
        name: 'Élargir',
        showInPlugin: true,
        items: [
          { name: 'Côtés (x2)', type: 'SIMPLE', price: 17, showInPlugin: true, id: '6.pull-ela-1' },
          { name: 'Épaules (x2)', type: 'SIMPLE', price: 19, showInPlugin: true, id: '6.pull-ela-2' },
          { name: 'Manches (x2 - sans boutonnière)', type: 'SIMPLE', price: 24, showInPlugin: true, id: '6.pull-ela-3' },
          { name: 'Taille', type: 'SIMPLE', price: 20, showInPlugin: true, id: '6.pull-ela-4' },
          { name: 'Dos', type: 'SIMPLE', price: 13, showInPlugin: true, id: '6.pull-ela-5' },

          { name: 'Côtés (x2)', type: 'COMPLEX', price: 42, showInPlugin: true, id: '6.pull-ela-6' },
          { name: 'Épaules (x2)', type: 'COMPLEX', price: 47, showInPlugin: true, id: '6.pull-ela-7' },
          { name: 'Manches (x2 - sans boutonnière)', type: 'COMPLEX', price: 60, showInPlugin: true, id: '6.pull-ela-8' },
          { name: 'Taille', type: 'COMPLEX', price: 50, showInPlugin: true, id: '6.pull-ela-9' },
          { name: 'Dos', type: 'COMPLEX', price: 32, showInPlugin: true, id: '6.pull-ela-10' },
        ],
      },
      {
        name: 'Raccourcir',
        showInPlugin: true,
        items: [
          { name: 'Longueur', type: 'SIMPLE', price: 14, showInPlugin: true, id: '6.pull-rac-1' },
          { name: 'Manches (x2 - par le bas)', type: 'SIMPLE', price: 12, showInPlugin: true, id: '6.pull-rac-2' },
          { name: 'Manches (x2 - par l’épaule)', type: 'SIMPLE', price: 37, showInPlugin: true, id: '6.pull-rac-3' },

          { name: 'Longueur', type: 'COMPLEX', price: 35, showInPlugin: true, id: '6.pull-rac-4' },
          { name: 'Manches (x2 - par le bas)', type: 'COMPLEX', price: 30, showInPlugin: true, id: '6.pull-rac-5' },
          { name: 'Manches (x2 - par l’épaule)', type: 'COMPLEX', price: 92, showInPlugin: true, id: '6.pull-rac-6' },
        ],
      },
      {
        name: 'Rallonger',
        showInPlugin: true,
        items: [
          { name: 'Longueur', type: 'SIMPLE', price: 17, showInPlugin: true, id: '6.pull-ral-1' },
          { name: 'Manches (x2 - par le bas)', type: 'SIMPLE', price: 12, showInPlugin: true, id: '6.pull-ral-2' },
          { name: 'Manches (x2 - par l’épaule)', type: 'SIMPLE', price: 37, showInPlugin: true, id: '6.pull-ral-3' },

          { name: 'Longueur', type: 'COMPLEX', price: 42, showInPlugin: true, id: '6.pull-ral-4' },
          { name: 'Manches (x2 - par le bas)', type: 'COMPLEX', price: 30, showInPlugin: true, id: '6.pull-ral-5' },
          { name: 'Manches (x2 - par l’épaule)', type: 'COMPLEX', price: 92, showInPlugin: true, id: '6.pull-ral-6' },
        ],
      },
      {
        name: 'Broderie main',
        items: [
          { name: 'Initiales', type: 'SIMPLE', price: 15, id: '6.pull-bro-1', multiple: true },
          { name: 'Un mot (10 lettres - petite police)', type: 'SIMPLE', price: 22, id: '6.pull-bro-2', multiple: true },
          { name: 'Un mot (10 lettres - grosse police)', type: 'SIMPLE', price: 45, id: '6.pull-bro-3', multiple: true },
          { name: 'Un mot (10 lettres - grosse police pleine)', type: 'SIMPLE', price: 80, id: '6.pull-bro-21', multiple: true },

          { name: 'Initiales', type: 'COMPLEX', price: 37, id: '6.pull-bro-4', multiple: true },
          { name: 'Un mot (10 lettres - petite police)', type: 'COMPLEX', price: 55, id: '6.pull-bro-5', multiple: true },
          { name: 'Un mot (10 lettres - grosse police)', type: 'COMPLEX', price: 112, id: '6.pull-bro-6', multiple: true },
          { name: 'Un mot (10 lettres - grosse police pleine)', type: 'COMPLEX', price: 200, id: '6.pull-bro-22', multiple: true },

          { name: 'Dessin creux < 5cm', price: 20, type: 'SIMPLE', id: '6.pull-bro-7', multiple: true },
          { name: 'Dessin creux < 12cm', price: 30, type: 'SIMPLE', id: '6.pull-bro-8', multiple: true },
          { name: 'Dessin creux < 25cm', price: 35, type: 'SIMPLE', id: '6.pull-bro-9', multiple: true },
          { name: 'Dessin plein forme simple < 5cm', price: 45, type: 'SIMPLE', id: '6.pull-bro-10', multiple: true },
          { name: 'Dessin plein complexe < 5cm', price: 75, type: 'SIMPLE', id: '6.pull-bro-11', multiple: true },
          { name: 'Dessin plein forme simple < 12cm', price: 65, type: 'SIMPLE', id: '6.pull-bro-12', multiple: true },
          { name: 'Dessin plein complexe < 12cm', price: 140, type: 'SIMPLE', id: '6.pull-bro-13', multiple: true },

          { name: 'Dessin creux < 5cm', price: 40, type: 'COMPLEX', id: '6.pull-bro-14', multiple: true },
          { name: 'Dessin creux < 12cm', price: 60, type: 'COMPLEX', id: '6.pull-bro-15', multiple: true },
          { name: 'Dessin creux < 25cm', price: 70, type: 'COMPLEX', id: '6.pull-bro-16', multiple: true },
          { name: 'Dessin plein forme simple < 5cm', price: 90, type: 'COMPLEX', id: '6.pull-bro-17', multiple: true },
          { name: 'Dessin plein complexe < 5cm', price: 150, type: 'COMPLEX', id: '6.pull-bro-18', multiple: true },
          { name: 'Dessin plein forme simple < 12cm', price: 130, type: 'COMPLEX', id: '6.pull-bro-19', multiple: true },
          { name: 'Dessin plein complexe < 12cm', price: 280, type: 'COMPLEX', id: '6.pull-bro-20', multiple: true },
        ],
      },
      {
        name: 'Divers',
        items: [
          { name: 'Poignets (x2) : remplacement', type: 'SIMPLE', price: 14, id: '6.pull-div-1' },
          { name: 'Coudières (x2) : ajout / remplacement', type: 'SIMPLE', price: 18, id: '6.pull-div-2', mostCommonCategoryPosition: 3 },
          { name: 'Épaulettes (x2) : ajout / remplacement', type: 'SIMPLE', price: 21, id: '6.pull-div-3' },
          { name: 'Manches (x2) : ajout / remplacement', type: 'SIMPLE', price: 50, id: '6.pull-div-4' },
          { name: 'Zip : ajout / remplacement', type: 'SIMPLE', price: 16, id: '6.pull-div-5', multiple: true },
          { name: 'Zip invisible', type: 'SIMPLE', price: 23, id: '6.pull-div-6', multiple: true },
          { name: 'Poche (x1) : ajout / remplacement', type: 'SIMPLE', price: 14, id: '6.pull-div-7', multiple: true },
          { name: 'Col : ajout / remplacement', type: 'SIMPLE', price: 38, id: '6.pull-div-8', multiple: true },
          { name: 'Boutonnière (petite) : création', type: 'SIMPLE', price: 47, id: '6.pull-div-9', multiple: true, hidden: true },
          { name: 'Boutonnière (x1) : création', type: 'SIMPLE', price: 9, id: '6.pull-div-31', multiple: true },
          { name: 'Bouton (x1) : ajout / remplacement', type: 'SIMPLE', price: 2, id: '6.pull-div-10', multiple: true, mostCommonCategoryPosition: 5 },
          { name: 'Trou (x1) : remaillage', type: 'SIMPLE', price: 6, id: '6.pull-div-33', multiple: true },
          { name: 'Déchirure : réparation', type: 'SIMPLE', price: 8, id: '6.pull-div-11', multiple: true, mostCommonCategoryPosition: 1 },
          { name: 'Passant : ajout / remplacement', type: 'SIMPLE', price: 8, id: '6.pull-div-12', multiple: true },
          { name: 'Couture d’appoint / décousu - simple', type: 'SIMPLE', price: 5, id: '6.pull-div-13', multiple: true, mostCommonCategoryPosition: 2 },
          { name: 'Couture d’appoint / décousu - complexe', type: 'SIMPLE', price: 11, id: '6.pull-div-14', multiple: true },
          { name: 'Couture complexe avec empiècement', type: 'SIMPLE', price: 23, id: '6.pull-div-15', multiple: true },

          { name: 'Poignets (x2) : remplacement', type: 'COMPLEX', price: 35, id: '6.pull-div-16' },
          { name: 'Coudières (x2) : ajout / remplacement', type: 'COMPLEX', price: 45, id: '6.pull-div-17', mostCommonCategoryPosition: 3 },
          { name: 'Épaulettes (x2) : ajout / remplacement / retrait', type: 'COMPLEX', price: 52, id: '6.pull-div-18' },
          { name: 'Manches (x2) : ajout / remplacement', type: 'COMPLEX', price: 125, id: '6.pull-div-19' },
          { name: 'Zip : ajout / remplacement / retrait', type: 'COMPLEX', price: 40, id: '6.pull-div-20', multiple: true },
          { name: 'Zip invisible', type: 'COMPLEX', price: 57, id: '6.pull-div-21', multiple: true },
          { name: 'Poche (x1) : ajout / remplacement', type: 'COMPLEX', price: 35, id: '6.pull-div-22', multiple: true },
          { name: 'Col : ajout / remplacement', type: 'COMPLEX', price: 112, id: '6.pull-div-23', multiple: true },
          { name: 'Boutonnière (petite) : création', type: 'COMPLEX', price: 117, id: '6.pull-div-24', multiple: true, hidden: true },
          { name: 'Boutonnière (x1) : création', type: 'COMPLEX', price: 12, id: '6.pull-div-32', multiple: true },
          { name: 'Bouton (x1) : ajout / remplacement', type: 'COMPLEX', price: 5, id: '6.pull-div-25', multiple: true, mostCommonCategoryPosition: 5 },
          { name: 'Trou (x1) : remaillage', type: 'COMPLEX', price: 6, id: '6.pull-div-34', multiple: true },
          { name: 'Déchirure : réparation', type: 'COMPLEX', price: 20, id: '6.pull-div-26', multiple: true, mostCommonCategoryPosition: 1 },
          { name: 'Passant : ajout / remplacement', type: 'COMPLEX', price: 20, id: '6.pull-div-27', multiple: true },
          { name: 'Couture d’appoint / décousu - simple', type: 'COMPLEX', price: 12, id: '6.pull-div-28', multiple: true, mostCommonCategoryPosition: 2 },
          { name: 'Couture d’appoint / décousu - complexe', type: 'COMPLEX', price: 27, id: '6.pull-div-29', multiple: true },
          { name: 'Couture complexe avec empiècement', type: 'COMPLEX', price: 57, id: '6.pull-div-30', multiple: true },
        ],
      },
    ],
  },


  {
    name: 'Lingerie & maillot',
    slug: 'ling',
    hasMostCommonCategory: true,
    showInPlugin: true,
    types: [
      { slug: 'SIMPLE', name: 'basique' },
      { slug: 'COMPLEX', name: 'lingerie fine' },
    ],
    categories: [
      {
        name: 'Cintrer',
        showInPlugin: true,
        items: [
          { name: 'Tour de poitrine', type: 'SIMPLE', price: 9, showInPlugin: true, id: '6.ling-cin-1' },
          { name: 'Bonnets (x2)', type: 'SIMPLE', price: 12, liningUnitPrice: 3, showInPlugin: true, id: '6.ling-cin-2' },
          { name: 'Bonnets avec armature (x2)', type: 'SIMPLE', price: 24, liningUnitPrice: 5, showInPlugin: true, id: '6.ling-cin-3', hidden: true },
          { name: 'Tour de taille', type: 'SIMPLE', price: 20, showInPlugin: true, id: '6.ling-cin-4' },

          { name: 'Tour de poitrine', type: 'COMPLEX', price: 22, showInPlugin: true, id: '6.ling-cin-5' },
          { name: 'Bonnets (x2)', type: 'COMPLEX', price: 30, liningUnitPrice: 3, showInPlugin: true, id: '6.ling-cin-6' },
          { name: 'Bonnets avec armature (x2)', type: 'COMPLEX', price: 40, liningUnitPrice: 5, showInPlugin: true, id: '6.ling-cin-7', hidden: true },
          { name: 'Tour de taille', type: 'COMPLEX', price: 30, showInPlugin: true, id: '6.ling-cin-8' },
        ],
      },
      {
        name: 'Élargir',
        showInPlugin: true,
        items: [
          { name: 'Tour de poitrine', type: 'SIMPLE', price: 10, showInPlugin: true, id: '6.ling-ela-1' },
          { name: 'Tour de taille', type: 'SIMPLE', price: 20, showInPlugin: true, id: '6.ling-ela-2' },

          { name: 'Tour de poitrine', type: 'COMPLEX', price: 25, showInPlugin: true, id: '6.ling-ela-3' },
          { name: 'Tour de taille', type: 'COMPLEX', price: 40, showInPlugin: true, id: '6.ling-ela-4' },
        ],
      },
      {
        name: 'Raccourcir',
        showInPlugin: true,
        items: [
          { name: 'Bretelles (x2)', type: 'SIMPLE', price: 8, showInPlugin: true, id: '6.ling-rac-1' },
          { name: 'Forme culotte / caleçon', type: 'SIMPLE', price: 20, liningUnitPrice: 2, showInPlugin: true, id: '6.ling-rac-2' },
          { name: 'Ourlet', type: 'SIMPLE', price: 12, liningUnitPrice: 2, showInPlugin: true, id: '6.ling-rac-3', mostCommonCategoryPosition: 5 },

          { name: 'Bretelles (x2)', type: 'COMPLEX', price: 20, showInPlugin: true, id: '6.ling-rac-4' },
          { name: 'Forme culotte', type: 'COMPLEX', price: 30, liningUnitPrice: 2, showInPlugin: true, id: '6.ling-rac-5' },
        ],
      },
      {
        name: 'Rallonger',
        showInPlugin: true,
        items: [
          { name: 'Bretelles (x2)', type: 'SIMPLE', price: 9, showInPlugin: true, id: '6.ling-ral-1' },
          { name: 'Forme culotte / caleçon', type: 'SIMPLE', price: 22, liningUnitPrice: 2, showInPlugin: true, id: '6.ling-ral-2' },
          { name: 'Longueur', type: 'SIMPLE', price: 14, liningUnitPrice: 3, showInPlugin: true, id: '6.ling-ral-3' },

          { name: 'Bretelles (x2)', type: 'COMPLEX', price: 22, showInPlugin: true, id: '6.ling-ral-4' },
          { name: 'Forme culotte', type: 'COMPLEX', price: 35, liningUnitPrice: 2, showInPlugin: true, id: '6.ling-ral-5' },
        ],
      },
      {
        name: 'Broderie main',
        items: [
          { name: 'Initiales', type: 'SIMPLE', price: 15, id: '6.ling-bro-1', multiple: true },
          { name: 'Un mot (10 lettres - petite police)', type: 'SIMPLE', price: 22, id: '6.ling-bro-2', multiple: true },
          { name: 'Un mot (10 lettres - grosse police)', type: 'SIMPLE', price: 45, id: '6.ling-bro-3', multiple: true },

          { name: 'Initiales', type: 'COMPLEX', price: 37, id: '6.ling-bro-4', multiple: true },
          { name: 'Un mot (10 lettres - petite police)', type: 'COMPLEX', price: 55, id: '6.ling-bro-5', multiple: true },
          { name: 'Un mot (10 lettres - grosse police)', type: 'COMPLEX', price: 112, id: '6.ling-bro-6', multiple: true },

          { name: 'Dessin creux < 5cm', price: 10, type: 'SIMPLE', id: '6.ling-bro-7', multiple: true },
          { name: 'Dessin creux < 12cm', price: 17, type: 'SIMPLE', id: '6.ling-bro-8', multiple: true },
          { name: 'Dessin creux < 25cm', price: 25, type: 'SIMPLE', id: '6.ling-bro-9', multiple: true },
          { name: 'Dessin plein forme simple < 5cm', price: 35, type: 'SIMPLE', id: '6.ling-bro-10', multiple: true },
          { name: 'Dessin plein complexe < 5cm', price: 65, type: 'SIMPLE', id: '6.ling-bro-11', multiple: true },
          { name: 'Dessin plein forme simple < 12cm', price: 55, type: 'SIMPLE', id: '6.ling-bro-12', multiple: true },
          { name: 'Dessin plein complexe < 12cm', price: 130, type: 'SIMPLE', id: '6.ling-bro-13', multiple: true },

          { name: 'Dessin creux < 5cm', price: 20, type: 'COMPLEX', id: '6.ling-bro-14', multiple: true },
          { name: 'Dessin creux < 12cm', price: 34, type: 'COMPLEX', id: '6.ling-bro-15', multiple: true },
          { name: 'Dessin creux < 25cm', price: 50, type: 'COMPLEX', id: '6.ling-bro-16', multiple: true },
          { name: 'Dessin plein forme simple < 5cm', price: 70, type: 'COMPLEX', id: '6.ling-bro-17', multiple: true },
          { name: 'Dessin plein complexe < 5cm', price: 130, type: 'COMPLEX', id: '6.ling-bro-18', multiple: true },
          { name: 'Dessin plein forme simple < 12cm', price: 110, type: 'COMPLEX', id: '6.ling-bro-19', multiple: true },
          { name: 'Dessin plein complexe < 12cm', price: 260, type: 'COMPLEX', id: '6.ling-bro-20', multiple: true },
        ],
      },
      {
        name: 'Divers',
        items: [
          { name: 'Zip : ajout / remplacement / retrait', type: 'SIMPLE', price: 10, liningUnitPrice: 1, id: '6.ling-div-1', multiple: true },
          { name: 'Zip invisible', type: 'SIMPLE', price: 13, liningUnitPrice: 1, id: '6.ling-div-2', multiple: true },
          { name: 'Bretelles (x2) : ajout / remplacement', type: 'SIMPLE', price: 12, id: '6.ling-div-3', mostCommonCategoryPosition: 4 },
          { name: 'Bretelles (x2) : retrait', type: 'SIMPLE', price: 9, id: '6.ling-div-4' },
          { name: 'Boutonnière (petite) : création', type: 'SIMPLE', price: 20, id: '6.ling-div-5', multiple: true, hidden: true },
          { name: 'Boutonnière (x1) : création', type: 'SIMPLE', price: 7, id: '6.ling-div-27', multiple: true },
          { name: 'Bouton (x1) : ajout / remplacement', type: 'SIMPLE', price: 2, id: '6.ling-div-6', multiple: true },
          { name: 'Déchirure : réparation', type: 'SIMPLE', price: 6, liningUnitPrice: 2, id: '6.ling-div-7', multiple: true, mostCommonCategoryPosition: 2 },
          { name: 'Élastique : ajout / remplacement', type: 'SIMPLE', price: 7, liningUnitPrice: 1, id: '6.ling-div-8', multiple: true, mostCommonCategoryPosition: 3 },
          { name: 'Attache dos : remplacement', type: 'SIMPLE', price: 10, id: '6.ling-div-9', multiple: true },
          { name: 'Armature (x2) : remplacement', type: 'SIMPLE', price: 8, id: '6.ling-div-10', multiple: true, hidden: true },
          { name: 'Baleines (x2) : remplacement / réparation', type: 'SIMPLE', price: 12, liningUnitPrice: 8, id: '6.ling-div-11', multiple: true },
          { name: 'Couture d’appoint / décousu - simple', type: 'SIMPLE', price: 4, liningUnitPrice: 1, id: '6.ling-div-12', multiple: true, mostCommonCategoryPosition: 1 },
          { name: 'Couture d’appoint / décousu - complexe', type: 'SIMPLE', price: 8, liningUnitPrice: 1, id: '6.ling-div-13', multiple: true },

          { name: 'Zip : ajout / remplacement / retrait', type: 'COMPLEX', price: 25, liningUnitPrice: 1, id: '6.ling-div-14', multiple: true },
          { name: 'Zip invisible', type: 'COMPLEX', price: 32, liningUnitPrice: 1, id: '6.ling-div-15', multiple: true },
          { name: 'Bretelles (x2) : ajout / remplacement', type: 'COMPLEX', price: 15, id: '6.ling-div-16', mostCommonCategoryPosition: 4 },
          { name: 'Bretelles (x2) : retrait', type: 'COMPLEX', price: 12, id: '6.ling-div-17' },
          { name: 'Boutonnière (petite) : création', type: 'COMPLEX', price: 50, id: '6.ling-div-18', multiple: true, hidden: true },
          { name: 'Boutonnière (x1) : création', type: 'COMPLEX', price: 10, liningUnitPrice: 1, id: '6.ling-div-28', multiple: true },
          { name: 'Bouton (x1) : ajout / remplacement', type: 'COMPLEX', price: 5, id: '6.ling-div-19', multiple: true },
          { name: 'Déchirure : réparation', type: 'COMPLEX', price: 15, liningUnitPrice: 2, id: '6.ling-div-20', multiple: true, mostCommonCategoryPosition: 2 },
          { name: 'Élastique : ajout / remplacement', type: 'COMPLEX', price: 17, liningUnitPrice: 1, id: '6.ling-div-21', multiple: true, mostCommonCategoryPosition: 3 },
          { name: 'Attache dos : remplacement', type: 'COMPLEX', price: 15, id: '6.ling-div-22', multiple: true },
          { name: 'Armature (x2) : remplacement', type: 'COMPLEX', price: 13, id: '6.ling-div-23', multiple: true, hidden: true },
          { name: 'Baleines (x2) : remplacement / réparation', type: 'COMPLEX', price: 17, liningUnitPrice: 17, id: '6.ling-div-24', multiple: true },
          { name: 'Couture d’appoint / décousu - simple', type: 'COMPLEX', price: 10, liningUnitPrice: 1, id: '6.ling-div-25', multiple: true, mostCommonCategoryPosition: 1 },
          { name: 'Couture d’appoint / décousu - complexe', type: 'COMPLEX', price: 20, liningUnitPrice: 1, id: '6.ling-div-26', multiple: true },
        ],
      },
    ],
  },


  {
    name: 'Décoration',
    slug: 'deco',
    hasMostCommonCategory: false,
    types: [],
    categories: [
      {
        name: 'Rideaux',
        items: [
          { name: 'Ourlet - largeur <199cm', price: 20, liningUnitPrice: 10, id: '6.deco-rid-1', multiple: true },
          { name: 'Ourlet - largeur >200cm', price: 30, liningUnitPrice: 10, id: '6.deco-rid-2', multiple: true },
          { name: 'Couture d’appoint / décousu - simple', price: 5, liningUnitPrice: 2, id: '6.deco-rid-3', multiple: true },
          { name: 'Couture d’appoint / décousu - complexe', price: 15, liningUnitPrice: 7, id: '6.deco-rid-4', multiple: true },
          { name: 'Déchirure : réparation', price: 20, liningUnitPrice: 10, id: '6.deco-rid-5', multiple: true },
          { name: 'Supplément fil de plomb', price: 10, id: '6.deco-rid-6', multiple: true },
          { name: 'Assemblage côté - hauteur <199cm', price: 30, liningUnitPrice: 10, id: '6.deco-rid-7', multiple: true },
          { name: 'Assemblage côté - hauteur >200cm', price: 40, liningUnitPrice: 10, id: '6.deco-rid-8', multiple: true },
          { name: 'Ajout de fronces - largeur <199cm', price: 30, id: '6.deco-rid-9', multiple: true },
          { name: 'Ajout de fronces - largeur >200cm', price: 37, id: '6.deco-rid-10', multiple: true },
        ],
      },
      {
        name: 'Coussins',
        items: [
          { name: 'Coussin (moins de 45 cm)', price: 14, id: '6.deco-cou-1', multiple: true },
          { name: 'Coussin (plus de 45 cm)', price: 18, id: '6.deco-cou-2', multiple: true },
          { name: 'Zip', price: 15, id: '6.deco-cou-3', multiple: true },
          { name: 'Zip invisible', price: 22, id: '6.deco-cou-4', multiple: true },
          { name: 'Déchirure : réparation', price: 6, id: '6.deco-cou-5', multiple: true },
          { name: 'Couture d’appoint / décousu - simple', price: 4, id: '6.deco-cou-6', multiple: true },
          { name: 'Couture d’appoint / décousu - complexe', price: 10, id: '6.deco-cou-7', multiple: true },
        ],
      },
      {
        name: 'Linge de maison',
        items: [
          { name: 'Plus d’1m60 - longueur', price: 20, id: '6.deco-mai-1', multiple: true },
          { name: 'Plus d’1m60 - largeur', price: 20, id: '6.deco-mai-2', multiple: true },
          { name: 'Plus d’1m60 - les deux', price: 40, id: '6.deco-mai-3', multiple: true },
          { name: 'Moins d’1m60 - longueur', price: 15, id: '6.deco-mai-4', multiple: true },
          { name: 'Moins d’1m60 - largeur', price: 15, id: '6.deco-mai-5', multiple: true },
          { name: 'Moins d’1m60 - les deux', price: 30, id: '6.deco-mai-6', multiple: true },
          { name: 'Moins d’1m - longueur', price: 10, id: '6.deco-mai-7', multiple: true },
          { name: 'Moins d’1m - largeur', price: 10, id: '6.deco-mai-8', multiple: true },
          { name: 'Moins d’1m - les deux', price: 20, id: '6.deco-mai-9', multiple: true },
          { name: 'Zip', price: 15, id: '6.deco-mai-10', multiple: true },
          { name: 'Zip invisible', price: 22, id: '6.deco-mai-11', multiple: true },
          { name: 'Couture d’appoint / décousu - simple', price: 5, liningUnitPrice: 2, id: '6.deco-mai-12', multiple: true },
          { name: 'Couture d’appoint / décousu - complexe', price: 15, liningUnitPrice: 7, id: '6.deco-mai-13', multiple: true },
          { name: 'Déchirure : réparation', price: 6, id: '6.deco-mai-14', multiple: true },
          { name: 'Bouton (x1) : ajout / remplacement', price: 1, id: '6.deco-mai-15', multiple: true },
        ],
      },
      {
        name: 'Broderie main',
        items: [
          { name: 'Initiales', price: 10, id: '6.deco-bro-1', multiple: true },
          { name: 'Un mot (10 lettres - petite police)', price: 15, id: '6.deco-bro-2', multiple: true },
          { name: 'Un mot (10 lettres - grosse police)', price: 45, id: '6.deco-bro-3', multiple: true },
          { name: 'Un mot (10 lettres - grosse police pleine)', price: 80, id: '6.deco-bro-11', multiple: true },
          { name: 'Dessin creux < 5cm', price: 10, id: '6.deco-bro-4', multiple: true },
          { name: 'Dessin creux < 12cm', price: 17, id: '6.deco-bro-5', multiple: true },
          { name: 'Dessin creux < 25cm', price: 25, id: '6.deco-bro-6', multiple: true },
          { name: 'Dessin plein forme simple < 5cm', price: 35, id: '6.deco-bro-7', multiple: true },
          { name: 'Dessin plein complexe < 5cm', price: 65, id: '6.deco-bro-8', multiple: true },
          { name: 'Dessin plein forme simple < 12cm', price: 55, id: '6.deco-bro-9', multiple: true },
          { name: 'Dessin plein complexe < 12cm', price: 130, id: '6.deco-bro-10', multiple: true },
        ],
      },
    ],
  },

  {
    name: 'Robe de mariée',
    slug: 'mari',
    hasMostCommonCategory: true,
    types: [],
    categories: [
      {
        name: 'Cintrer',
        items: [
          { name: 'Côtés (x2)', price: 63, liningUnitPrice: 28, id: '6.mari-cin-1' },
          { name: 'Épaules (x2)', price: 42, liningUnitPrice: 8, id: '6.mari-cin-2' },
          { name: 'Manches (x2 - sans boutonnière)', price: 49, id: '6.mari-cin-3' },
          { name: 'Manches (x2 - boutonnière)', price: 63, id: '6.mari-cin-4' },
          { name: 'Bustier / taille', price: 70, liningUnitPrice: 8, id: '6.mari-cin-5', mostCommonCategoryPosition: 5, mostCommonCategoryLabel: 'Cintrer taille' },
          { name: 'Dos', price: 63, liningUnitPrice: 8, id: '6.mari-cin-6' },
        ],
      },
      {
        name: 'Élargir',
        items: [
          { name: 'Côtés (x2)', price: 35, liningUnitPrice: 12, id: '6.mari-ela-1' },
          { name: 'Épaules (x2)', price: 42, liningUnitPrice: 8, id: '6.mari-ela-2' },
          { name: 'Manches (x2 - sans boutonnière)', price: 49, id: '6.mari-ela-3' },
          { name: 'Manches (x2 - boutonnière)', price: 63, id: '6.mari-ela-4' },
          { name: 'Bustier / taille', price: 70, liningUnitPrice: 8, id: '6.mari-ela-5' },
          { name: 'Dos', price: 63, liningUnitPrice: 8, id: '6.mari-ela-6' },
        ],
      },
      {
        name: 'Raccourcir',
        items: [
          { name: 'Ourlet simple', price: 63, liningUnitPrice: 13, id: '6.mari-rac-1', mostCommonCategoryPosition: 1 },
          { name: 'Ourlet à volants', price: 84, liningUnitPrice: 15, id: '6.mari-rac-2' },
          { name: 'Ourlet revers', price: 65, liningUnitPrice: 13, id: '6.mari-rac-3' },
          { name: 'Manches (x2 - sans boutonnière)', price: 16, id: '6.mari-rac-4' },
          { name: 'Manches (x2 - boutonnière)', price: 32, id: '6.mari-rac-5' },
          { name: 'Bretelles (x2)', price: 28, id: '6.mari-rac-6' },
        ],
      },
      {
        name: 'Rallonger',
        items: [
          { name: 'Longueur simple', price: 63, liningUnitPrice: 13, id: '6.mari-ral-1' },
          { name: 'Longueur à volants', price: 84, liningUnitPrice: 15, id: '6.mari-ral-2' },
          { name: 'Ourlets (x2) : suppression des revers', price: 59, liningUnitPrice: 11, id: '6.mari-ral-3' },
          { name: 'Manches (x2 - sans boutonnière)', price: 16, id: '6.mari-ral-4' },
          { name: 'Manches (x2 - boutonnière)', price: 32, id: '6.mari-ral-5' },
          { name: 'Bretelles (x2)', price: 28, id: '6.mari-ral-6' },
        ],
      },
      {
        name: 'Broderie main',
        items: [
          { name: 'Initiales', price: 20, id: '6.mari-bro-1', multiple: true },
          { name: 'Un mot (10 lettres - petite police)', price: 28, id: '6.mari-bro-2', multiple: true },
          { name: 'Un mot (10 lettres - grosse police)', price: 52, id: '6.mari-bro-3', multiple: true },
          { name: 'Dessin creux < 5cm', price: 18, id: '6.mari-bro-4', multiple: true },
          { name: 'Dessin creux < 12cm', price: 25, id: '6.mari-bro-5', multiple: true },
          { name: 'Dessin creux < 25cm', price: 35, id: '6.mari-bro-6', multiple: true },
          { name: 'Dessin plein forme simple < 5cm', price: 48, id: '6.mari-bro-7', multiple: true },
          { name: 'Dessin plein complexe < 5cm', price: 80, id: '6.mari-bro-8', multiple: true },
          { name: 'Dessin plein forme simple < 12cm', price: 71, id: '6.mari-bro-9', multiple: true },
          { name: 'Dessin plein complexe < 12cm', price: 200, id: '6.mari-bro-10', multiple: true },
        ],
      },
      {
        name: 'Divers',
        items: [
          { name: 'Doublure : ajout / remplacement', price: 69, id: '6.mari-div-1' },
          { name: 'Zip : ajout / remplacement', price: 49, liningUnitPrice: 13, id: '6.mari-div-2', multiple: true, mostCommonCategoryPosition: 2 },
          { name: 'Zip invisible', price: 63, liningUnitPrice: 15, id: '6.mari-div-3', multiple: true },
          { name: 'Bretelles (x2) : ajout / remplacement / retrait', price: 31, id: '6.mari-div-4' },
          { name: 'Ceinture : ajout / remplacement', price: 42, id: '6.mari-div-5', multiple: true },
          { name: 'Fente : ajout / remplacement / retrait', price: 29, liningUnitPrice: 6, id: '6.mari-div-6', multiple: true },
          { name: 'Pince (x1) : ajout', price: 25, liningUnitPrice: 25, id: '6.mari-div-7', multiple: true },
          { name: 'Poche (x1) : changement de doublure', price: 20, id: '6.mari-div-8', multiple: true },
          { name: 'Poche (x1) : ajout / remplacement', price: 25, id: '6.mari-div-9', multiple: true },
          { name: 'Boutonnière (x1) : création', price: 10, liningUnitPrice: 1, id: '6.mari-div-10', multiple: true },
          { name: 'Bouton (x1) : ajout / remplacement', price: 4, id: '6.mari-div-11', multiple: true },
          { name: 'Bouton pression (x1) : ajout / remplacement', price: 10, id: '6.mari-div-12', multiple: true },
          { name: 'Déchirure : réparation', price: 17, id: '6.mari-div-13', multiple: true, mostCommonCategoryPosition: 3 },
          { name: 'Passant : ajout / remplacement', price: 14, id: '6.mari-div-14', multiple: true },
          { name: 'Couture d’appoint / décousu - simple', price: 11, id: '6.mari-div-15', multiple: true, mostCommonCategoryPosition: 4 },
          { name: 'Couture d’appoint / décousu - complexe', price: 20, id: '6.mari-div-16', multiple: true },
          { name: 'Couture complexe avec empiècement', price: 35, id: '6.mari-div-17', multiple: true },
        ],
      },
    ],
  },


  {
    name: 'Accessoires',
    slug: 'acce',
    hasMostCommonCategory: false,
    showInPlugin: false,
    types: [
      { slug: 'SIMPLE', name: 'basique' },
      { slug: 'COMPLEX', name: 'habillé' },
    ],
    categories: [
      {
        name: 'Broderie main',
        items: [
          { name: 'Initiales', type: 'SIMPLE', price: 10, id: '6.acce-bro-1', multiple: true },
          { name: 'Un mot (10 lettres - petite police)', type: 'SIMPLE', price: 15, id: '6.acce-bro-2', multiple: true },
          { name: 'Un mot (10 lettres - grosse police)', type: 'SIMPLE', price: 45, id: '6.acce-bro-3', multiple: true },
          { name: 'Un mot (10 lettres - grosse police pleine)', type: 'SIMPLE', price: 80, id: '6.acce-bro-21', multiple: true },

          { name: 'Initiales', type: 'COMPLEX', price: 17, id: '6.acce-bro-4', multiple: true },
          { name: 'Un mot (10 lettres - petite police)', type: 'COMPLEX', price: 25, id: '6.acce-bro-5', multiple: true },
          { name: 'Un mot (10 lettres - grosse police)', type: 'COMPLEX', price: 49, id: '6.acce-bro-6', multiple: true },
          { name: 'Un mot (10 lettres - grosse police pleine)', type: 'COMPLEX', price: 90, id: '6.acce-bro-22', multiple: true },

          { name: 'Dessin creux < 5cm', price: 10, type: 'SIMPLE', id: '6.acce-bro-7', multiple: true },
          { name: 'Dessin creux < 12cm', price: 17, type: 'SIMPLE', id: '6.acce-bro-8', multiple: true },
          { name: 'Dessin creux < 25cm', price: 25, type: 'SIMPLE', id: '6.acce-bro-9', multiple: true },
          { name: 'Dessin plein forme simple < 5cm', price: 35, type: 'SIMPLE', id: '6.acce-bro-10', multiple: true },
          { name: 'Dessin plein complexe < 5cm', price: 65, type: 'SIMPLE', id: '6.acce-bro-11', multiple: true },
          { name: 'Dessin plein forme simple < 12cm', price: 55, type: 'SIMPLE', id: '6.acce-bro-12', multiple: true },
          { name: 'Dessin plein complexe < 12cm', price: 130, type: 'SIMPLE', id: '6.acce-bro-13', multiple: true },

          { name: 'Dessin creux < 5cm', price: 15, type: 'COMPLEX', id: '6.acce-bro-14', multiple: true },
          { name: 'Dessin creux < 12cm', price: 22, type: 'COMPLEX', id: '6.acce-bro-15', multiple: true },
          { name: 'Dessin creux < 25cm', price: 30, type: 'COMPLEX', id: '6.acce-bro-16', multiple: true },
          { name: 'Dessin plein forme simple < 5cm', price: 42, type: 'COMPLEX', id: '6.acce-bro-17', multiple: true },
          { name: 'Dessin plein complexe < 5cm', price: 75, type: 'COMPLEX', id: '6.acce-bro-18', multiple: true },
          { name: 'Dessin plein forme simple < 12cm', price: 65, type: 'COMPLEX', id: '6.acce-bro-19', multiple: true },
          { name: 'Dessin plein complexe < 12cm', price: 145, type: 'COMPLEX', id: '6.acce-bro-20', multiple: true },
        ],
      },
      {
        name: 'Divers',
        items: [
          { name: 'Zip : ajout / remplacement / retrait', type: 'SIMPLE', price: 12, id: '6.acce-div-1', multiple: true },
          { name: 'Zip invisible', type: 'SIMPLE', price: 18, id: '6.acce-div-2', multiple: true },
          { name: 'Bouton (x1) : ajout / remplacement', type: 'SIMPLE', price: 1, id: '6.acce-div-3', multiple: true, mostCommonCategoryPosition: 3 },
          { name: 'Déchirure : réparation', type: 'SIMPLE', price: 6, id: '6.acce-div-4', multiple: true, mostCommonCategoryPosition: 1 },
          { name: 'Élastique : ajout / remplacement', type: 'SIMPLE', price: 7, liningUnitPrice: 1, id: '6.acce-div-5', multiple: true, mostCommonCategoryPosition: 3 },
          { name: 'Couture d’appoint / décousu - simple', type: 'SIMPLE', price: 4, id: '6.acce-div-6', multiple: true, mostCommonCategoryPosition: 2 },
          { name: 'Couture d’appoint / décousu - complexe', type: 'SIMPLE', price: 10, id: '6.acce-div-7', multiple: true },
          { name: 'Couture complexe avec empiècement', type: 'SIMPLE', price: 15, id: '6.acce-div-8', multiple: true },
          { name: 'Ajustement', type: 'SIMPLE', price: 3, id: '6.acce-div-17', multiple: true },

          { name: 'Zip : ajout / remplacement / retrait', type: 'COMPLEX', price: 17, id: '6.acce-div-9', multiple: true },
          { name: 'Zip invisible', type: 'COMPLEX', price: 27, id: '6.acce-div-10', multiple: true },
          { name: 'Bouton (x1) : ajout / remplacement', type: 'COMPLEX', price: 2, id: '6.acce-div-11', multiple: true, mostCommonCategoryPosition: 3 },
          { name: 'Déchirure : réparation', type: 'COMPLEX', price: 8, id: '6.acce-div-12', multiple: true, mostCommonCategoryPosition: 1 },
          { name: 'Élastique : ajout / remplacement', type: 'COMPLEX', price: 17, liningUnitPrice: 1, id: '6.acce-div-13', multiple: true, mostCommonCategoryPosition: 3 },
          { name: 'Couture d’appoint / décousu - simple', type: 'COMPLEX', price: 4, id: '6.acce-div-14', multiple: true, mostCommonCategoryPosition: 2 },
          { name: 'Couture d’appoint / décousu - complexe', type: 'COMPLEX', price: 10, id: '6.acce-div-15', multiple: true },
          { name: 'Couture complexe avec empiècement', type: 'COMPLEX', price: 18, id: '6.acce-div-16', multiple: true },
          { name: 'Ajustement', type: 'COMPLEX', price: 3, id: '6.acce-div-18', multiple: true },
        ],
      },
      {
        name: 'Créations',
        items: [
          { name: 'Tote bag classique (35 x 40cm)', type: 'SIMPLE', price: 10, id: '6.acce-cre-1' },
          { name: 'Tote bag grand (40 x 50cm)', type: 'SIMPLE', price: 25, id: '6.acce-cre-2' },
          { name: 'Pochette / trousse petit (15 x 10cm)', type: 'SIMPLE', price: 7, id: '6.acce-cre-3' },
          { name: 'Pochette / trousse moyen (20 x 20cm)', type: 'SIMPLE', price: 12, id: '6.acce-cre-4' },
          { name: 'Pochette / trousse grand (25 x 25cm)', type: 'SIMPLE', price: 18, id: '6.acce-cre-5' },
          { name: 'Sac petit(20 x 30cm)', type: 'SIMPLE', price: 20, id: '6.acce-cre-6' },
          { name: 'Sac moyen (30 x 30cm)', type: 'SIMPLE', price: 35, id: '6.acce-cre-7' },
          { name: 'Sac grand (plus de 30 x 30cm)', type: 'SIMPLE', price: 50, id: '6.acce-cre-8' },
          { name: 'Anse chaîne & oeillet', type: 'SIMPLE', price: 15, id: '6.acce-cre-9' },
          { name: 'Anse tissu', type: 'SIMPLE', price: 2, id: '6.acce-cre-10' },
          { name: 'Autre anse (acheté par le client)', type: 'SIMPLE', price: 2, id: '6.acce-cre-11' },
          { name: 'Fermeture zip', type: 'SIMPLE', price: 6, id: '6.acce-cre-12' },
          { name: 'Fermeture bouton pression', type: 'SIMPLE', price: 3, id: '6.acce-cre-13' },
          { name: 'Fermeture bouton', type: 'SIMPLE', price: 1, id: '6.acce-cre-14' },
          { name: 'Doublure / thermocollant', type: 'SIMPLE', price: 5, id: '6.acce-cre-15' },
          { name: 'Poche intérieure', type: 'SIMPLE', price: 3, id: '6.acce-cre-16' },
          { name: 'Clous de protection sous le sac', type: 'SIMPLE', price: 5, id: '6.acce-cre-17' },
          { name: 'Poche extérieure', type: 'SIMPLE', price: 4, id: '6.acce-cre-18' },

          { name: 'Tote bag classique (35 x 40cm)', type: 'COMPLEX', price: 10, id: '6.acce-cre-19' },
          { name: 'Tote bag grand (40 x 50cm)', type: 'COMPLEX', price: 25, id: '6.acce-cre-20' },
          { name: 'Pochette / trousse petit (15 x 10cm)', type: 'COMPLEX', price: 7, id: '6.acce-cre-21' },
          { name: 'Pochette / trousse moyen (20 x 20cm)', type: 'COMPLEX', price: 12, id: '6.acce-cre-22' },
          { name: 'Pochette / trousse grand (25 x 25cm)', type: 'COMPLEX', price: 18, id: '6.acce-cre-23' },
          { name: 'Sac petit(20 x 30cm)', type: 'COMPLEX', price: 20, id: '6.acce-cre-24' },
          { name: 'Sac moyen (30 x 30cm)', type: 'COMPLEX', price: 35, id: '6.acce-cre-25' },
          { name: 'Sac grand (plus de 30 x 30cm)', type: 'COMPLEX', price: 50, id: '6.acce-cre-26' },
          { name: 'Anse chaîne & oeillet', type: 'COMPLEX', price: 15, id: '6.acce-cre-27' },
          { name: 'Anse tissu', type: 'COMPLEX', price: 2, id: '6.acce-cre-28' },
          { name: 'Autre anse (acheté par le client)', type: 'COMPLEX', price: 2, id: '6.acce-cre-29' },
          { name: 'Fermeture zip', type: 'COMPLEX', price: 6, id: '6.acce-cre-30' },
          { name: 'Fermeture bouton pression', type: 'COMPLEX', price: 3, id: '6.acce-cre-31' },
          { name: 'Fermeture bouton', type: 'COMPLEX', price: 1, id: '6.acce-cre-32' },
          { name: 'Doublure / thermocollant', type: 'COMPLEX', price: 5, id: '6.acce-cre-33' },
          { name: 'Poche intérieure', type: 'COMPLEX', price: 3, id: '6.acce-cre-34' },
          { name: 'Clous de protection sous le sac', type: 'COMPLEX', price: 5, id: '6.acce-cre-35' },
          { name: 'Poche extérieure', type: 'COMPLEX', price: 4, id: '6.acce-cre-36' },
        ],
      },
    ],
  },
];

export default rawPricing;
