import React from 'react';
import { navigate } from 'gatsby';
import { Container, Content } from '../../components/home/homeStyledComponents';
import MenuBar from '../../components/home/MenuBar';
import Footer from '../../components/home/Footer';
import callApi from '../../services/api';
import OrdersList from '../../components/MyAccount/OrdersList';
import Layout from '../../layouts/index';

class MyOrders extends React.Component {
  constructor() {
    super();
    this.state = { orders: [] };
  }

  componentDidMount() {
    if (typeof window === 'undefined') return;
    window.scrollTo(0, 0);

    callApi('public/orders').then((res) => {
      this.setState({ orders: res.orders });
    }).catch(() => {
      navigate('/se-connecter');
    });
  }

  render() {
    return (
      <Layout>
        <Container>
          <Content>
            <MenuBar />
            <OrdersList orders={this.state.orders} />
            <Footer />
          </Content>
        </Container>
      </Layout>
    );
  }
}

export default MyOrders;
