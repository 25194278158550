/* eslint max-len: 0, object-curly-newline: 0 */
// ###################################################
// # @README before updating price:                  #
// # * don't change the id of an item                #
// # * don't delete an item, set `hidden: true`      #
// # * @TODO: update price on tilli-site             #
// ###################################################

const metadata = {
  deliveryFee: 5,
  minOrderAmount: 20,
};

const pricing = [
  {
    cloth: 'Manteau/veste',
    slug: 'mant',
    categories: [
      {
        name: 'Cintrer',
        items: [
          { name: 'Taille sans doublure', price: 41, id: 'mant-cin-1' },
          { name: 'Taille avec doublure', price: 59, id: 'mant-cin-2' },
          { name: 'Dos sans doublure', price: 20, id: 'mant-cin-3' },
          { name: 'Dos avec doublure', price: 29, id: 'mant-cin-4' },
          { name: 'Manches', price: 59, id: 'mant-cin-5' },
        ],
      },
      {
        name: 'Raccourcir',
        items: [
          { name: 'Longueur sans doublure', price: 29, id: 'mant-rac-1' },
          { name: 'Longueur avec doublure', price: 41, id: 'mant-rac-2' },
          { name: 'Manches sans boutonnière', price: 29, id: 'mant-rac-3', hidden: true },
          { name: 'Manches avec boutonnière', price: 50, id: 'mant-rac-4', hidden: true },
          { name: 'Manche reprise par le bas', price: 50, id: 'mant-rac-5' },
          { name: 'Manche reprise par l’épaule', price: 120, id: 'mant-rac-6' },
        ],
      },
      {
        name: 'Rallonger',
        items: [
          { name: 'Longueur sans doublure', price: 41, id: 'mant-ral-1' },
          { name: 'Longueur avec doublure', price: 50, id: 'mant-ral-2' },
          { name: 'Manches', price: 41, id: 'mant-ral-3' },
        ],
      },
      {
        name: 'Divers',
        items: [
          { name: 'Changement doublure court', price: 102, id: 'mant-div-1' },
          { name: 'Changement doublure long', price: 149, id: 'mant-div-2' },
          { name: 'Rajout doublure', price: 96, id: 'mant-div-3' },
          { name: 'Coudières (x2)', price: 24, id: 'mant-div-4' },
          { name: 'Ajouter ou retirer des épaulettes (x2)', price: 50, id: 'mant-div-5' },
          { name: 'Rajout/remplacement zip', price: 50, id: 'mant-div-6', multiple: true },
          { name: 'Couture d’appoint ou décousue - simple', price: 4, id: 'mant-div-7', multiple: true },
          { name: 'Couture d’appoint ou décousue - complexe', price: 10, id: 'mant-div-8', multiple: true },
          { name: 'Couture complexe avec empiècement', price: 22, id: 'mant-div-17', multiple: true },
          { name: 'Déchiré', price: 8, id: 'mant-div-9', multiple: true },
          { name: 'Changement doublure poche', price: 20, id: 'mant-div-10', multiple: true },
          { name: 'Bouton  à l’unité', price: 3, id: 'mant-div-11', multiple: true },
          { name: 'Faire ou déplacer un passant', price: 10, id: 'mant-div-12', multiple: true },
          { name: 'Broderie main: initiales', price: 12, id: 'mant-div-13', multiple: true },
          { name: 'Broderie main: le mot (petite police)', price: 17, id: 'mant-div-14', multiple: true },
          { name: 'Broderie main: le mot (grosse police)', price: 25, id: 'mant-div-15', multiple: true },
          { name: 'Changer / remplacer un col', price: 66, id: 'mant-div-16' },
        ],
      },
    ],
  },
  {
    cloth: 'Chemise',
    slug: 'chem',
    categories: [
      {
        name: 'Cintrer',
        items: [
          { name: 'Épaules (pinces)', price: 29, id: 'chem-cin-1', multiple: true },
          { name: 'Taille', price: 29, id: 'chem-cin-2' },
          { name: 'Manches (x2)', price: 29, id: 'chem-cin-3' },
        ],
      },
      {
        name: 'Raccourcir',
        items: [
          { name: 'Longueur des manches (x2)', price: 29, id: 'chem-rac-1' },
          { name: 'Bas de chemise', price: 25, id: 'chem-rac-2' },
        ],
      },
      {
        name: 'Divers',
        items: [
          { name: 'Changer/remplacer col', price: 50, id: 'chem-div-1' },
          { name: 'Changer/remplacer poignets (x2)', price: 23, id: 'chem-div-2' },
          { name: 'Changer/remplacer manches', price: 41, id: 'chem-div-3' },
          { name: 'Couture d’appoint ou décousue - simple', price: 4, id: 'chem-div-4', multiple: true },
          { name: 'Couture d’appoint ou décousue - complexe', price: 10, id: 'chem-div-5', multiple: true },
          { name: 'Couture complexe avec empiècement', price: 22, id: 'chem-div-15', multiple: true },
          { name: 'Déchiré', price: 8, id: 'chem-div-6', multiple: true },
          { name: 'Changement doublure poche', price: 20, id: 'chem-div-7', multiple: true },
          { name: 'Bouton  à l’unité', price: 2, id: 'chem-div-8', multiple: true },
          { name: 'Faire ou déplacer un passant', price: 10, id: 'chem-div-9', multiple: true },
          { name: 'Zip invisible', price: 29, id: 'chem-div-10', multiple: true },
          { name: 'Zip', price: 15, id: 'chem-div-11', multiple: true },
          { name: 'Broderie main: initiales', price: 12, id: 'chem-div-12', multiple: true },
          { name: 'Broderie main: le mot (petite police)', price: 17, id: 'chem-div-13', multiple: true },
          { name: 'Broderie main: le mot (grosse police)', price: 25, id: 'chem-div-14', multiple: true },
        ],
      },
    ],
  },
  {
    cloth: 'Pantalon',
    slug: 'pant',
    categories: [
      {
        name: 'Cintrer',
        items: [
          { name: 'Ceinture', price: 41, id: 'pant-cint-1' },
          { name: 'Bas de jambe', price: 29, id: 'pant-cint-2' },
          { name: 'Toute la jambe', price: 50, id: 'pant-cint-3' },
          { name: 'Entrejambes/fessier', price: 29, id: 'pant-cint-4' },
        ],
      },
      {
        name: 'Élargir',
        items: [
          { name: 'Ceinture', price: 50, id: 'pant-ela-1' },
        ],
      },
      {
        name: 'Ourlet',
        items: [
          { name: 'Jeans/simple', price: 12, id: 'pant-our-1' },
          { name: 'Pantalon costume (main)', price: 20, id: 'pant-our-2' },
          { name: 'Ourlet revers', price: 17, id: 'pant-our-3' },
        ],
      },
      {
        name: 'Divers',
        items: [
          { name: 'Zip', price: 17, id: 'pant-div-1', multiple: true },
          { name: 'Zip invisible', price: 29, id: 'pant-div-2', multiple: true },
          { name: 'Rajout pinces', price: 20, id: 'pant-div-3', multiple: true },
          { name: 'Changement ceinture', price: 41, id: 'pant-div-4' },
          { name: 'Couture d’appoint ou décousue - simple', price: 4, id: 'pant-div-5', multiple: true },
          { name: 'Couture d’appoint ou décousue - complexe', price: 10, id: 'pant-div-6', multiple: true },
          { name: 'Couture complexe avec empiècement', price: 22, id: 'pant-div-14', multiple: true },
          { name: 'Déchiré', price: 8, id: 'pant-div-7', multiple: true },
          { name: 'Changement doublure poche', price: 20, id: 'pant-div-8', multiple: true },
          { name: 'Bouton  à l’unité', price: 3, id: 'pant-div-9', multiple: true },
          { name: 'Faire ou déplacer un passant', price: 7, id: 'pant-div-13', multiple: true },
          { name: 'Broderie main: initiales', price: 12, id: 'pant-div-10', multiple: true },
          { name: 'Broderie main: le mot (petite police)', price: 17, id: 'pant-div-11', multiple: true },
          { name: 'Broderie main: le mot (grosse police)', price: 25, id: 'pant-div-12', multiple: true },
        ],
      },
    ],
  },
  {
    cloth: 'Jupe',
    slug: 'jupe',
    categories: [
      {
        name: 'Élargir',
        items: [
          { name: 'Changement doublure complète', price: 50, id: 'jupe-ela-1', hidden: true },
          { name: 'Changement doublure partiel', price: 14, id: 'jupe-ela-2', hidden: true },
          { name: 'Ceinture', price: 29, id: 'jupe-ela-5' },
          { name: 'Des deux côtés doublés', price: 41, id: 'jupe-ela-4' },
          { name: 'Des deux côtés non doublés', price: 29, id: 'jupe-ela-3' },
        ],
      },
      {
        name: 'Raccourcir',
        items: [
          { name: 'Ourlet simple', price: 18, id: 'jupe-rac-1', multiple: true },
          { name: 'Ourlet ample', price: 29, id: 'jupe-rac-2', multiple: true },
          { name: 'Ourlet main simple', price: 36, id: 'jupe-rac-5', multiple: true },
          { name: 'Ourlet main ample', price: 46, id: 'jupe-rac-6', multiple: true },
          { name: 'Ourlet mouchoir simple', price: 25, id: 'jupe-rac-7', multiple: true, hidden: true },
          { name: 'Ourlet mouchoir ample', price: 22, id: 'jupe-rac-8', multiple: true, hidden: true },
          { name: 'Cintrer par la ceinture avec zip', price: 41, id: 'jupe-rac-3', multiple: true },
          { name: 'Cintrer les deux côtés', price: 29, id: 'jupe-rac-4' },
        ],
      },
      {
        name: 'Divers',
        items: [
          { name: 'Zip', price: 21, id: 'jupe-div-1', multiple: true },
          { name: 'Zip invisible', price: 29, id: 'jupe-div-2', multiple: true },
          { name: 'Couture d’appoint ou décousue - simple', price: 4, id: 'jupe-div-3', multiple: true },
          { name: 'Couture d’appoint ou décousue - complexe', price: 10, id: 'jupe-div-4', multiple: true },
          { name: 'Couture complexe avec empiècement', price: 22, id: 'jupe-div-15', multiple: true },
          { name: 'Déchiré', price: 8, id: 'jupe-div-5', multiple: true },
          { name: 'Changement doublure poche', price: 20, id: 'jupe-div-6', multiple: true },
          { name: 'Bouton à l’unité', price: 3, id: 'jupe-div-7', multiple: true },
          { name: 'Faire ou déplacer un passant', price: 7, id: 'jupe-div-8', multiple: true },
          { name: 'Fermer fente', price: 23, id: 'jupe-div-9', multiple: true },
          { name: 'Broderie main: initiales', price: 12, id: 'jupe-div-10', multiple: true },
          { name: 'Broderie main: le mot (petite police)', price: 17, id: 'jupe-div-11', multiple: true },
          { name: 'Broderie main: le mot (grosse police)', price: 25, id: 'jupe-div-12', multiple: true },
          { name: 'Changement doublure complète', price: 50, id: 'jupe-div-13' },
          { name: 'Changement doublure partiel', price: 23, id: 'jupe-div-14' },
        ],
      },
    ],
  },
  {
    cloth: 'Robe simple',
    slug: 'robe',
    categories: [
      {
        name: 'élargir',
        items: [
          { name: 'Des deux côtés doublés', price: 41, id: 'robe-ela-1' },
          { name: 'Des deux côtés non doublés', price: 29, id: 'robe-ela-2' },
          { name: 'Bas de robe avec fente ou ample', price: 29, id: 'robe-ela-3', hidden: true },
          { name: 'Bas de robe simple', price: 20, id: 'robe-ela-4', hidden: true },
          { name: 'Dos', price: 29, id: 'robe-ela-9' },
          { name: 'Bustier / taille', price: 29, id: 'robe-ela-5' },
          { name: 'Les manches sans boutonnière', price: 29, id: 'robe-ela-6' },
          { name: 'Manche avec boutonnière', price: 50, id: 'robe-ela-7' },
          { name: 'Les bretelles (x2)', price: 15, id: 'robe-ela-8', hidden: true },
        ],
      },
      {
        name: 'Rallonger',
        items: [
          { name: 'Bas de robe simple', price: 18, id: 'robe-ral-1', multiple: true },
          { name: 'Bas de robe avec fente ou ample', price: 29, id: 'robe-ral-2', multiple: true },
          { name: 'Bas de robe main simple', price: 36, id: 'robe-ral-3', multiple: true },
          { name: 'Bas de robe main ample', price: 46, id: 'robe-ral-4', multiple: true },
          { name: 'Les bretelles (x2)', price: 15, id: 'robe-ral-5' },
        ],
      },
      {
        name: 'Cintrer',
        items: [
          { name: 'Bustier /taille', price: 29, id: 'robe-cin-1', multiple: true },
          { name: 'Les manches sans boutonnière', price: 29, id: 'robe-cin-2', multiple: true },
          { name: 'Manche avec boutonnière', price: 50, id: 'robe-cin-3', multiple: true },
          { name: 'Des deux côtés doublés', price: 41, id: 'robe-cin-4', multiple: true },
          { name: 'Des deux côtés non doublés', price: 29, id: 'robe-cin-5', multiple: true },
          { name: 'Dos', price: 29, id: 'robe-cin-6', multiple: true },
          { name: 'Épaules', price: 20, id: 'robe-cin-7', multiple: true },
        ],
      },
      {
        name: 'Raccourcir',
        items: [
          { name: 'Bustier /taille', price: 29, id: 'robe-rac-1', hidden: true },
          { name: 'Les manches sans boutonnière', price: 29, id: 'robe-rac-2', hidden: true },
          { name: 'Manche avec boutonnière', price: 50, id: 'robe-rac-3', hidden: true },
          { name: 'Des deux côtés doublés', price: 41, id: 'robe-rac-4', hidden: true },
          { name: 'Des deux côtés non doublés', price: 29, id: 'robe-rac-5', hidden: true },
          { name: 'Ourlet simple', price: 20, id: 'robe-rac-6', hidden: true },
          { name: 'Ourlet ample', price: 29, id: 'robe-rac-7', hidden: true },
          { name: 'Ourlet main simple', price: 22, id: 'robe-rac-9', hidden: true },
          { name: 'Ourlet main ample', price: 28, id: 'robe-rac-10', hidden: true },
          { name: 'Ourlet mouchoir simple', price: 25, id: 'robe-rac-11', hidden: true },
          { name: 'Ourlet mouchoir ample', price: 22, id: 'robe-rac-12', hidden: true },
          { name: 'Bas de robe simple', price: 18, id: 'robe-rac-13', multiple: true },
          { name: 'Bas de robe ample', price: 29, id: 'robe-rac-14', multiple: true },
          { name: 'Bas de robe main simple', price: 36, id: 'robe-rac-15', multiple: true },
          { name: 'Bas de robe main ample', price: 46, id: 'robe-rac-16', multiple: true },
          { name: 'Raccourcir les bretelles (x2)', price: 15, id: 'robe-rac-8' },
        ],
      },
      {
        name: 'Divers',
        items: [
          { name: 'Zip', price: 28, id: 'robe-div-1', multiple: true },
          { name: 'Zip invisible', price: 41, id: 'robe-div-2', multiple: true },
          { name: 'Couture d’appoint ou décousue - simple', price: 4, id: 'robe-div-3', multiple: true },
          { name: 'Couture d’appoint ou décousue - complexe', price: 10, id: 'robe-div-4', multiple: true },
          { name: 'Couture complexe avec empiècement', price: 22, id: 'robe-div-15', multiple: true },
          { name: 'Déchiré', price: 8, id: 'robe-div-5', multiple: true },
          { name: 'Changement doublure poche', price: 20, id: 'robe-div-6', multiple: true },
          { name: 'Bouton à l’unité', price: 2.25, id: 'robe-div-7', multiple: true },
          { name: 'Faire ou déplacer un passant', price: 10, id: 'robe-div-8', multiple: true },
          { name: 'Fermer une fente', price: 23, id: 'robe-div-13', multiple: true },
          { name: 'Rajouter des bretelles (x2)', price: 29, id: 'robe-div-9', multiple: true },
          { name: 'Broderie main: initiales', price: 12, id: 'robe-div-10', multiple: true },
          { name: 'Broderie main: le mot (petite police)', price: 17, id: 'robe-div-11', multiple: true },
          { name: 'Broderie main: le mot (grosse police)', price: 25, id: 'robe-div-12', multiple: true },
          { name: 'Pince (x1)', price: 20, id: 'robe-div-14', multiple: true },
        ],
      },
    ],
  },
  {
    cloth: 'Robe de soirée & mariage',
    slug: 'robe_soiree',
    categories: [
      {
        name: 'Élargir',
        items: [
          { name: 'Des deux côtés doublés', price: 42, id: 'robe_soiree-ela-1' },
          { name: 'Des deux côtés non doublés', price: 35, id: 'robe_soiree-ela-2' },
          { name: 'Ourlet simple', price: 19, id: 'robe_soiree-ela-3', multiple: true, hidden: true },
          { name: 'Ourlet ample', price: 25, id: 'robe_soiree-ela-4', multiple: true, hidden: true },
          { name: 'Ourlet main simple', price: 39, id: 'robe_soiree-ela-5', multiple: true, hidden: true },
          { name: 'Ourlet main ample', price: 50, id: 'robe_soiree-ela-6', multiple: true, hidden: true },
          { name: 'Ourlet mouchoir simple', price: 22, id: 'robe_soiree-ela-7', multiple: true, hidden: true },
          { name: 'Ourlet mouchoir ample', price: 33, id: 'robe_soiree-ela-8', multiple: true, hidden: true },
          { name: 'Les manches sans boutonnière', price: 49, id: 'robe_soiree-ela-10' },
          { name: 'Manche avec boutonnière', price: 63, id: 'robe_soiree-ela-11' },
          { name: 'Les bretelles (x2)', price: 17, id: 'robe_soiree-ela-12', hidden: true },
          { name: 'Bustier / taille', price: 63, id: 'robe_soiree-ela-9' },
          { name: 'Dos', price: 63, id: 'robe_soiree-ela-13' },
        ],
      },
      {
        name: 'Rallonger',
        items: [
          { name: 'Ourlet simple', price: 35, id: 'robe_soiree-ral-1', multiple: true },
          { name: 'Ourlet ample', price: 49, id: 'robe_soiree-ral-2', multiple: true },
          { name: 'Ourlet main simple', price: 63, id: 'robe_soiree-ral-3', multiple: true },
          { name: 'Ourlet main ample', price: 84, id: 'robe_soiree-ral-4', multiple: true },
          { name: 'Les bretelles (x2)', price: 28, id: 'robe_soiree-ral-5' },
        ],
      },
      {
        name: 'Cintrer',
        items: [
          { name: 'Bustier / taille', price: 63, id: 'robe_soiree-cin-1' },
          { name: 'Les manches sans boutonnière', price: 49, id: 'robe_soiree-cin-2' },
          { name: 'Manche avec boutonnière', price: 63, id: 'robe_soiree-cin-3' },
          { name: 'Des deux côtés doublés', price: 91, id: 'robe_soiree-cin-4' },
          { name: 'Des deux côtés non doublés', price: 63, id: 'robe_soiree-cin-5' },
          { name: 'Dos', price: 63, id: 'robe_soiree-cin-6' },
          { name: 'Épaules', price: 42, id: 'robe_soiree-cin-7' },
        ],
      },
      {
        name: 'Raccourcir',
        items: [
          { name: 'Bustier /taille', price: 44, id: 'robe_soiree-rac-1', hidden: true },
          { name: 'Les manches sans boutonnière', price: 28, id: 'robe_soiree-rac-2', hidden: true },
          { name: 'Manche avec boutonnière', price: 44, id: 'robe_soiree-rac-3', hidden: true },
          { name: 'Des deux côtés doublés', price: 69, id: 'robe_soiree-rac-4', hidden: true },
          { name: 'Des deux côtés non doublés', price: 44, id: 'robe_soiree-rac-5', hidden: true },
          { name: 'Ourlet simple', price: 20, id: 'robe_soiree-rac-6', multiple: true, hidden: true },
          { name: 'Ourlet ample', price: 33, id: 'robe_soiree-rac-7', multiple: true, hidden: true },
          { name: 'Ourlet main simple', price: 39, id: 'robe_soiree-rac-8', multiple: true, hidden: true },
          { name: 'Ourlet main ample', price: 50, id: 'robe_soiree-rac-9', multiple: true, hidden: true },
          { name: 'Bas de robe simple', price: 35, id: 'robe_soiree-rac-11', multiple: true },
          { name: 'Bas de robe ample ', price: 49, id: 'robe_soiree-rac-12', multiple: true },
          { name: 'Bas de robe main simple', price: 63, id: 'robe_soiree-rac-13', multiple: true },
          { name: 'Bas de robe main ample', price: 84, id: 'robe_soiree-rac-14', multiple: true },
          { name: 'Raccourcir les bretelles (x2)', price: 28, id: 'robe_soiree-rac-10' },
        ],
      },
      {
        name: 'divers',
        items: [
          { name: 'Zip', price: 49, id: 'robe_soiree-div-1', multiple: true },
          { name: 'Zip invisible', price: 63, id: 'robe_soiree-div-2', multiple: true },
          { name: 'Couture d’appoint ou décousue - simple', price: 11, id: 'robe_soiree-div-3', multiple: true },
          { name: 'Couture d’appoint ou décousue - complexe', price: 20, id: 'robe_soiree-div-4', multiple: true },
          { name: 'Déchiré', price: 17, id: 'robe_soiree-div-5', multiple: true },
          { name: 'Changement doublure poche', price: 28, id: 'robe_soiree-div-6', multiple: true },
          { name: 'Bouton à l’unité (x1)', price: 4.2, id: 'robe_soiree-div-7', multiple: true },
          { name: 'Bouton pression (x1)', price: 7, id: 'robe_soiree-div-13', multiple: true },
          { name: 'Boutonnière (x1)', price: 7, id: 'robe_soiree-div-14', multiple: true },
          { name: 'Faire ou déplacer un passant', price: 14, id: 'robe_soiree-div-8', multiple: true },
          { name: 'Rajouter des bretelles (x2)', price: 31, id: 'robe_soiree-div-9' },
          { name: 'Broderie main: initiales', price: 9.8, id: 'robe_soiree-div-10', multiple: true },
          { name: 'Broderie main: le mot (petite police)', price: 14, id: 'robe_soiree-div-11', multiple: true },
          { name: 'Broderie main: le mot (grosse police)', price: 21, id: 'robe_soiree-div-12', multiple: true },
          { name: 'Pince (x1)', price: 25, id: 'robe_soiree-div-15', multiple: true },
        ],
      },
    ],
  },
  {
    cloth: 'Décoration',
    slug: 'deco',
    categories: [
      {
        name: 'Rideaux',
        items: [
          { name: 'Ourlet (moins de 2m)', price: 20, id: 'deco-rid-1', multiple: true },
          { name: 'Ourlet (plus de 2m)', price: 30, id: 'deco-rid-2', multiple: true },
        ],
      },
    ],
  },
];

export default pricing;

const pricingInverse = {};
pricing.forEach(
  cloth => cloth.categories.forEach(
    category => category.items.forEach(
      (item) => {
        if (pricingInverse[item.id]) {
          throw new Error(`Inverse pricing, duplicate key : ${item.id}`);
        }
        pricingInverse[item.id] = {
          ...item,
          cloth: cloth.cloth,
          category: category.name,
        };
      },
    ),
  ),
);

export { pricingInverse, metadata };
