import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import moment from 'moment';
import { colors } from '../styledComponents';
import OrderSummary from './OrderSummary';

const Card = styled.div`
  border: 1px solid ${colors.navy};
  margin: 20px;
`;

const Header = styled.div`
  background-color: ${colors.lightGrey};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid ${colors.navy};
  padding: 20px;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const TH = styled.span`
  font-weight: lighter;
`;

const Content = styled.div``;

const Id = styled.div`
  font-size: 11px;
  text-align: right;
  margin-top: 2px;
`;

const OrderCard = ({ order }) => (
  <Card>
    <Header>
      <Column>
        <TH>RDV 1</TH>
        {order.rdv1 && order.rdv1.begin ?
          <span>{moment(order.rdv1.begin).format('L')}</span> :
          <span>Pas de RDV 1 défini</span>
        }
      </Column>
      {order.rdv2 && order.rdv2.begin &&
        <Column>
          <TH>RDV 2</TH>
          <span>{moment(order.rdv2.begin).format('L')}</span>
        </Column>
      }
      {order.rdv3 && order.rdv3.begin &&
        <Column>
          <TH>RDV 3</TH>
          <span>{moment(order.rdv3.begin).format('L')}</span>
        </Column>
      }
      <Column>
        <TH>Couturier</TH>
        {order.couturier ?
          <span>{order.couturier.firstname} {order.couturier.lastname}</span>
          : <span>Pas encore de couturier assigné</span>
        }
      </Column>
      <Column>
        <TH>Total</TH>
        <span>{order.totalPaid}€</span>
      </Column>
    </Header>
    <Content>
      <OrderSummary order={order} />
    </Content>
    <Id>numéro unique de commande : {order._id}</Id>
  </Card>
);

OrderCard.propTypes = {
  order: PropTypes.shape({
    _id: PropTypes.string,
  }).isRequired,
};

export default OrderCard;
