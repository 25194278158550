import pricingV1, { pricingInverse as pricingInverseV1, metadata as metadataV1 } from './v1/pricings';
import pricingV2, { pricingInverse as pricingInverseV2, metadata as metadataV2 } from './v2/pricings';
import pricingV3, { pricingInverse as pricingInverseV3, metadata as metadataV3 } from './v3/pricings';
import pricingV4, { pricingInverse as pricingInverseV4, metadata as metadataV4 } from './v4/pricings';
import pricingV5, { pricingInverse as pricingInverseV5, metadata as metadataV5 } from './v5/pricings';
import pricingV6, { pricingInverse as pricingInverseV6, metadata as metadataV6 } from './v6/pricings';
import pricingV7, { pricingInverse as pricingInverseV7, metadata as metadataV7 } from './v7/pricings';
import pricingV8, { pricingInverse as pricingInverseV8, metadata as metadataV8 } from './v8/pricings';
import pricingV9, {
  pricingInverse as pricingInverseV9, metadata as metadataV9,
  upcyclingPricing as upcyclingPricingV9, upcyclingPricingInverse as upcyclingPricingInverseV9,
} from './v9/pricings';
import upcyclingPricing, { upcyclingPricingInverse } from './upcyclingPricing';

const latestVersionNumber = 9;

export function getLatestVersionNumber() {
  return latestVersionNumber;
}

export default function getPricing(pricingVersion) {
  switch (pricingVersion) {
    case 9:
      return pricingV9;
    case 8:
      return pricingV8;
    case 7:
      return pricingV7;
    case 6:
      return pricingV6;
    case 5:
      return pricingV5;
    case 4:
      return pricingV4;
    case 3:
      return pricingV3;
    case 2:
      return pricingV2;
    case 1:
    default:
      return pricingV1;
  }
}

export function getPricingInverse(pricingVersion) {
  switch (pricingVersion) {
    case 9:
      return pricingInverseV9;
    case 8:
      return pricingInverseV8;
    case 7:
      return pricingInverseV7;
    case 6:
      return pricingInverseV6;
    case 5:
      return pricingInverseV5;
    case 4:
      return pricingInverseV4;
    case 3:
      return pricingInverseV3;
    case 2:
      return pricingInverseV2;
    case 1:
    default:
      return pricingInverseV1;
  }
}

export function getMetadata(pricingVersion) {
  switch (pricingVersion) {
    case 9:
      return metadataV9;
    case 8:
      return metadataV8;
    case 7:
      return metadataV7;
    case 6:
      return metadataV6;
    case 5:
      return metadataV5;
    case 4:
      return metadataV4;
    case 3:
      return metadataV3;
    case 2:
      return metadataV2;
    case 1:
    default:
      return metadataV1;
  }
}

export function getUpcyclingPricing(pricingVersion) {
  switch (pricingVersion) {
    case 9:
      return upcyclingPricingV9;
    default:
      return upcyclingPricing;
  }
}

export function getUpcyclingPricingInverse(pricingVersion) {
  switch (pricingVersion) {
    case 9:
      return upcyclingPricingInverseV9;
    default:
      return upcyclingPricingInverse;
  }
}
