// *** Version: 7 ***

/* eslint max-len: 0 */

import rawPricing from './rawPricings';

// #####################################################################
// # @README before updating price:                                    #
// # * don't change the id of an item                                  #
// # * don't delete an item, set `hidden: true`                        #
// # * update version at the top of the file and in metadata object    #
// # * @TODO: update price on tilli-site                               #
// #####################################################################

const metadata = {
  minOrderAmount: 20,
  deliveryFee: 5,
  version: 7,
  mostCommonCategoryName: 'Les + courants',
  // put positions here rather than directly reorganizing the categories and clothes
  // because it's easier to keep up with the original excel price sheet
  clothPositions: [
    'pant', 'haut',
    'robe', 'mari',
    'mant', 'jupe',
    'pull', 'deco',
    'ling', 'comb',
    'acce', 'mask',
    'crea_tote', 'crea_sac',
    'crea_poch', 'crea_hous',
  ],
  categoryPositions: [
    'Ourlets',
    'Raccourcir',
    'Cintrer',
    'Rallonger',
    'Élargir',
    'Divers',
    'Broderie main',
    'Rideaux',
    'Coussins',
    'Créations',
  ],
};

export function addAutoComplex(clothes) {
  return clothes.map(cloth => ({
    ...cloth,
    categories: !(typeof cloth.autoComplexFactor === 'number')
      ? cloth.categories
      : cloth.categories.map(category => ({
        ...category,
        items: category.items.reduce((newItems, item) => [
          ...newItems,
          {
            ...item,
            type: item.type.replace(/SIMPLE/, 'COMPLEX'),
            price: item.price * (item.autoComplexFactor || cloth.autoComplexFactor),
          },
        ], category.items),
      })),
  }));
}

export function addAutoId(clothes) {
  return clothes.map(cloth => ({
    ...cloth,
    categories: !cloth.autoId
      ? cloth.categories
      : cloth.categories.map(category => ({
        ...category,
        items: category.items.map((item, index) => ({
          ...item,
          id: `${metadata.version}.${cloth.slug}-${category.autoIdPrefix}-${index + 1}`,
          multiple: category.autoMultiple ? true : undefined,
        })),
      })),
  }));
}

function getClothPosition(clothSlug) {
  return metadata.clothPositions.findIndex(clothSlugIt => clothSlugIt === clothSlug);
}

function sortClothes(clothes) {
  return clothes.sort((a, b) => getClothPosition(a.slug) > getClothPosition(b.slug) ? 1 : -1);
}

function getCategoryPosition(categoryName) {
  return metadata.categoryPositions.findIndex(categoryNameIt => categoryNameIt === categoryName);
}

function sortCategories(clothes) {
  return clothes.map((cloth) => {
    if (cloth.slug.includes('crea_')) return cloth;
    cloth.categories.sort((a, b) => getCategoryPosition(a.name) > getCategoryPosition(b.name) ? 1 : -1);
    return cloth;
  });
}

function getMostCommonCategory(cloth) {
  const items = [];
  cloth.categories.forEach(cat => cat.items.forEach((item) => {
    if (typeof item.mostCommonCategoryPosition === 'number') items.push(item);
  }));
  return {
    name: metadata.mostCommonCategoryName,
    mostCommonCategory: true,
    items: items.sort((a, b) => (a.mostCommonCategoryPosition > b.mostCommonCategoryPosition) ? 1 : -1),
  };
}

function addMostCommonCategory(clothes) {
  return clothes.map((cloth) => {
    if (!cloth.hasMostCommonCategory) return cloth;
    return {
      ...cloth,
      categories: [
        getMostCommonCategory(cloth),
        ...cloth.categories,
      ],
    };
  });
}

const autoComplexPricings = addAutoComplex(rawPricing);
const autoIdPricings = addAutoId(autoComplexPricings);
const sortedClothes = sortClothes(autoIdPricings);
const sortedClothesAndCategories = sortCategories(sortedClothes);
const pricing = addMostCommonCategory(sortedClothesAndCategories);

export default pricing;

const pricingInverse = {};
pricing.forEach(
  cloth => cloth.categories.filter(cat => cat.name !== metadata.mostCommonCategoryName)
    .forEach(
      category => category.items.forEach(
        (item) => {
          if (pricingInverse[item.id]) {
            throw new Error(`Inverse pricing, duplicate key : ${item.id}`);
          }
          pricingInverse[item.id] = {
            ...item,
            cloth: cloth.name,
            category: category.name,
          };
        },
      ),
    ),
);

export { pricingInverse, metadata };
