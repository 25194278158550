import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { colors } from '../styledComponents';
import OrderSummaryCloth from './OrderSummaryCloth';
import { getPricingInverse } from '../../core/pricings/index';

const SummaryRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: ${colors.navy};
  color: white;
  padding: 0px 5px;
`;

const OrderSummary = ({ order }) => (
  <div>
    {order.clothes && order.clothes.map((cloth, index) =>
      <OrderSummaryCloth cloth={cloth} pricingInverse={getPricingInverse(order.pricingVersion)} key={index} />)}
    {order.deliveryFee > 0 &&
      <SummaryRow>
        <span style={{ color: 'white', flex: 1 }}>
          Frais de livraison
        </span>
        <span style={{ color: 'white', flex: 1, textAlign: 'right' }}>
          {order.deliveryFee}€
        </span>
      </SummaryRow>
    }
  </div>
);

OrderSummary.propTypes = {
  order: PropTypes.shape({}).isRequired,
};

export default OrderSummary;
